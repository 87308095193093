export const CATEGORY_REAL_ESTATE = 'real estate';

export const ROLE_USER = 'drafter';
export const ROLE_VERIFIER = 'verifier';
export const ROLE_QA = 'qa';
export const ROLE_ADMIN = 'admin';

export const USER_ROLES = [
  ROLE_USER,
  ROLE_VERIFIER,
  ROLE_QA,
  ROLE_ADMIN,
];

export const STATUS_REJECTED = 'Rejected';
export const STATUS_VERIFIED_MISMATCH = 'VerifiedMismatch';
export const STATUS_NONE = 'None';
export const STATUS_DRAFT = 'Draft';
export const STATUS_DRAFT_REJECTED = 'DraftRejected';
export const STATUS_VERIFIED_MATCH = 'VerifiedMatch';
export const STATUS_VERIFIED_QA = 'VerifiedQA';
export const STATUS_VERIFIED_ADMIN = 'VerifiedAdmin';

export const ALI_STATUSES = [
  STATUS_REJECTED,
  STATUS_VERIFIED_MISMATCH,
  STATUS_NONE,
  STATUS_DRAFT,
  STATUS_DRAFT_REJECTED,
  STATUS_VERIFIED_MATCH,
  STATUS_VERIFIED_ADMIN,
];

export const TAG_PROPERTY_ENCLOSED_MALL = 'Enclosed Mall';
export const TAG_PROPERTY_OPEN_AIR = 'Open Air';
export const TAG_PROPERTY_SINGLE_TENANT = 'Single Tenant';
export const TAG_PROPERTY_STREET = 'Street';
export const TAG_PROPERTY_PARKING_LOT = 'Parking Lot';

export const TAG_PROPERTY_OPTIONS = [
  TAG_PROPERTY_ENCLOSED_MALL,
  TAG_PROPERTY_OPEN_AIR,
  TAG_PROPERTY_SINGLE_TENANT,
  TAG_PROPERTY_STREET,
  TAG_PROPERTY_PARKING_LOT,
];

export const PROPERTY_TYPES = [
  TAG_PROPERTY_ENCLOSED_MALL,
  TAG_PROPERTY_OPEN_AIR,
  TAG_PROPERTY_SINGLE_TENANT,
  TAG_PROPERTY_STREET,
];

export const SHOW_SOURCE_ALL = 'all';
export const SHOW_SOURCE_AGGDATA = 'aggdata';
export const SHOW_SOURCE_ROC = 'roc';

export const SHOW_SOURCES = {
  [SHOW_SOURCE_ALL]: 'Show All',
  [SHOW_SOURCE_AGGDATA]: 'Aggdata Only',
  [SHOW_SOURCE_ROC]: 'ROC Only',
};

export const LIST_ID = {
  GEOGRAPHIES: 0,
  ORPHAN: -1,
};

export const LIST_NAME = {};
LIST_NAME[LIST_ID.GEOGRAPHIES] = 'Geographies';
LIST_NAME[LIST_ID.ORPHAN] = 'Orphan Properties';

export const ISSUES_LEVEL = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const HIDE_FLAGS = [
  {
    name: 'None',
    value: '',
  },
  {
    name: 'Hide Traffic',
    value: 'hideTraffic',
  },
  {
    name: 'Hide From App',
    value: 'hideFromApp',
  }
];
export const POLYGON_FLAGS = [
  {
    name: 'None',
    value: '',
  },
  {
    name: 'Multi-Level',
    value: 'multi_level',
  },
  {
    name: 'Construction',
    value: 'construction',
  },
  {
    name: 'Mall Tenant',
    value: 'mall_tenant',
  },
  {
    name: 'Within-Store/Kiosk',
    value: 'within_store_or_kiosk',
  },
  {
    name: 'Residential',
    value: 'residential',
  },
];

export const NO_CONSTRUCTION_FLAG = 'None';
// polygon distance is measured in miles
export const NEAR_BY_POLYGON_DISTANCE = 0.2;

export const POLYGON_STATUS_NONE = 'None';
export const INCLUDE_GEOFENCES = true;

export const ISSUES_TYPE_OVERLAPPED_POLYGONS = 'Overlapped Polygons';
export const POLYGON_TYPE_LANDLORD = 'Landlord';
export const POLYGON_TYPE_RETAILER = 'Retailer';

export const ALI_FLAG_COBRANDED = 'coBranded';
export const ALI_FLAG_WITHINSTORE = 'withInStore';
export const ALI_FLAG_TOUCHING_BORDERS = 'touchingBorders';

export const TABS = {
  TAB_SEARCH_BY_LIST: 0,
  TAB_VIEW_BY_LIST: 1,
  TAB_SEARCH_THIS_AREA: 2,
  TAB_UPLOAD_ALIS: 3,
  TAB_NO_POLYGONS: 4,
  TAB_THASOS: 5,
  TAB_OUTLIER: 6,
};
export const ALI_POLYGONS_ZINDEX = 9999;
