import PropTypes from 'prop-types';
import jsonStringify from 'json-stable-stringify';

const withStylesPropTypes = {
  classes: PropTypes.shape().isRequired,
};

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

function sortArray(array, columnDef, desc) {
  array.sort((a, b) => {
    let ret = 0;
    let v1 = a[columnDef.sortKey || columnDef.dataKey];
    let v2 = b[columnDef.sortKey || columnDef.dataKey];

    if (columnDef.format) {
      v1 = columnDef.format(v1);
      v2 = columnDef.format(v2);
    }

    if (columnDef.numeric) ret = (v2 || 0) - (v1 || 0);
    else ret = (v2 || '').localeCompare(v1 || '');

    return desc ? ret : -ret;
  });
}

function filterArray(array, filter) {
  const fnFilter = (filterValue, dataValue, numeric) => {
    let ret = true;
    if (filterValue.length) {
      ret = false;
      if (numeric) {
        let m = filterValue.match(/^(\d+)\s*-\s*(\d+)$/);
        if (m) {
          const from = Number(m[1]);
          const to = Number(m[2]);
          ret = (dataValue >= from && dataValue <= to);
        } else {
          m = filterValue.match(/^([><!])\s*(\d+)$/);
          if (m) {
            const op = m[1];
            const val = Number(m[2]);
            switch (op) {
              case '>':
                ret = (dataValue > val);
                break;
              case '<':
                ret = (dataValue < val);
                break;
              case '!':
                ret = (dataValue !== val);
                break;
              default:
                ret = false;
                break;
            }
          } else {
            const val = Number(filterValue);
            ret = (dataValue === val);
          }
        }
      } else {
        switch (filterValue.charAt(0)) {
          case '=':
            ret = (dataValue && dataValue.toLowerCase() === filterValue.substring(1));
            break;
          case '!':
            ret = (dataValue && dataValue.toLowerCase() !== filterValue.substring(1));
            break;
          case '~':
            ret = (dataValue && dataValue.toLowerCase().indexOf(filterValue.substring(1)) < 0);
            break;
          default:
            ret = (dataValue && dataValue.toLowerCase().indexOf(filterValue) >= 0);
            break;
        }
      }
    }

    return ret;
  };

  let ret = array.map((item) => ({ ...item }));
  if (filter) {
    filter.forEach((f) => {
      const { value, columnDef } = f;
      const filterValue = (value || '').toLowerCase().trim();
      ret = ret.filter((v) => {
        const dataValue = v[columnDef.dataKey];
        if (columnDef.filterChildKey && typeof (dataValue) === 'object' && dataValue.length) {
          const filteredDataValue = dataValue.filter((dv) => fnFilter(filterValue, dv[columnDef.filterChildKey], columnDef.numeric));
          // eslint-disable-next-line no-param-reassign
          v[columnDef.dataKey] = filteredDataValue;
          return filteredDataValue.length;
        }
        return fnFilter(filterValue, dataValue, columnDef.numeric);
      });
    });
  }

  return ret;
}

function formatAddress(ali) {
  const fnNotEmpty = (s) => s && s.length;
  const parts = [];
  if (fnNotEmpty(ali.address)) parts.push(ali.address);
  if (fnNotEmpty(ali.addressLine2)) parts.push(ali.addressLine2);
  if (fnNotEmpty(ali.county)) parts.push(ali.county);
  if (fnNotEmpty(ali.city)) parts.push(ali.city);
  if (fnNotEmpty(ali.state)) parts.push(ali.state);
  if (fnNotEmpty(ali.zipCode)) parts.push(ali.zipCode);
  
  return parts.join(', ');
}

function hashCode(obj) {
  let hash = 0;
  const str = jsonStringify(obj);
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char; // eslint-disable-line
    hash &= hash; // eslint-disable-line
  }
  return hash;
}

function getLetterIndex(index) {
  let ret = '';
  let i = index;
  do {
    ret = ALPHABET.charAt(i % ALPHABET.length) + ret;
    i = Math.floor(i / ALPHABET.length) - 1;
  } while (i >= 0);

  return ret;
}

function downloadFile(filename, data, contenttype, enc) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${contenttype};${enc},${encodeURIComponent(data)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function downloadTextFile(filename, text) {
  downloadFile(filename, text, 'text/plain', 'charset=utf-8');
}

function downloadExcelFile(filename, data) {
  downloadFile(filename, data, 'application/vnd.ms-excel', 'base64');
}

function error(err) {
  // eslint-disable-next-line no-console
  console.error(err);
  if (err && err.response && err.response.data) {
    return err.response.data;
  }
  return new Error('Internal Error');
}

function pad(num, size) {
  const s = `0000${num}`;
  return s.substr(s.length - size);
}

function formatDate(date) {
  let ret = '';

  if (date) {
    const y = date.getFullYear();
    const m = (date.getMonth() + 1);
    const d = date.getDate();
    ret = `${pad(y, 4)}-${pad(m, 2)}-${pad(d, 2)}`;
  }

  return ret;
}

function resolveProperty(obj, path, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator);
  if (properties.length === 1) return obj[properties[0]];
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

function getHideTrafficForReason(val) {
  let str = "";
  if (val.length !== 0) {
    val = val[0];
    val.hideFor.map((e) => {
      for(const period of e.timePeriod) {
        if (period.onGoing && !("endAt" in period)) {
          str = str + e.errorType + ", "
        }else if (period.onGoing && ("endAt" in period)) {
          const endAt = Date.parse(e.endAt)
          if (Date.now() < endAt) {
            str = str + e.errorType + ", ";
          }
        }
      }
    })
    return str;
  }
  return "";
}

function getConstructionFlag(obj) {
  const constructionFlag = {
    none: 'None',
    within_store_or_kiosk: 'Within-Store/Kiosk',
    multi_level: 'Multi-Level',
    construction:'Construction',
    mall_tenant:'Mall Tenant',
    residential:'Residential'
  };
  const arr = obj['propertySet'];
  if(!arr) { return 'None'}
  if (arr.length === 0) {return 'None'}
  if (!arr[0]['constructionFlag']) {return 'None'}
  return constructionFlag[arr[0]['constructionFlag']];
}

export {
  withStylesPropTypes,
  sortArray,
  filterArray,
  formatAddress,
  hashCode,
  getLetterIndex,
  downloadTextFile,
  downloadExcelFile,
  downloadFile,
  ALPHABET,
  error,
  formatDate,
  resolveProperty,
  getHideTrafficForReason,
  getConstructionFlag
};

export default withStylesPropTypes;
