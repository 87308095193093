import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Button, ButtonGroup, TextField,
} from '@material-ui/core';
import { ThumbUp, ThumbDown } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../helper/misc';
import * as C from '../constants';
import User from '../service/user';

const useStyles = ((theme) => ({
  root: {
    display: 'block',
  },
  rejectPaper: {
    padding: '5px',
  },
  accept: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.success.light,
    },
  },
  reject: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

class ApproveBar extends React.Component {
  constructor(props) {
    super(props);

    const { polygons } = props;
    this.state = ApproveBar.getPolygonState(polygons);
  }

  componentDidUpdate(prevProps) {
    const { polygons } = this.props;
    if (polygons !== prevProps.polygons) {
      this.onPolygonsChanged(polygons);
    }
  }

  onPolygonsChanged(polygons) {
    this.setState(ApproveBar.getPolygonState(polygons));
  }

  onChange() {
    const { onChange } = this.props;
    const { polygons } = this.state;
    onChange({
      indexes: polygons.map((p) => p.index),
      status: polygons[0].properties.status,
      statusMessage: polygons[0].properties.statusMessage,
    });
  }

  static getPolygonState(polygons) {
    return {
      polygons,
    };
  }

  handleStatusChange(status) {
    const { polygons } = this.state;
    if (status !== C.STATUS_REJECTED) polygons[0].properties.statusMessage = null;
    polygons[0].properties.status = status;
    this.setState({
      polygons,
    });
    this.onChange();
  }

  handleReasonChange(event) {
    const { value } = event.target;
    const { polygons } = this.state;
    polygons[0].properties.statusMessage = value;
    this.setState({
      polygons,
    });
    this.onChange();
  }

  renderCurrentStatus() {
    const { polygons } = this.state;
    const { classes, canApprove } = this.props;

    if (canApprove) {
      switch (polygons[0].properties.status || '') {
        case C.STATUS_VERIFIED_ADMIN:
        case C.STATUS_VERIFIED_QA:
          return (
            <Button variant="contained" size="small" fullWidth className={classes.accept} startIcon={<ThumbUp />} onClick={() => this.handleStatusChange()}>Accepted</Button>
          );
        case C.STATUS_REJECTED:
          return (
            <Paper variant="outlined" className={classes.rejectPaper}>
              <Button variant="contained" size="small" fullWidth className={classes.reject} startIcon={<ThumbDown />} onClick={() => this.handleStatusChange()}>Rejected</Button>
              <TextField
                fullWidth
                name="Reason"
                label="Reason"
                size="small"
                margin="dense"
                value={polygons[0].properties.statusMessage || ''}
                onChange={(event) => this.handleReasonChange(event)}
              />
            </Paper>
          );
        default:
          return (
            <ButtonGroup size="small" fullWidth>
              <Button variant="outlined" size="small" className={classes.accept} onClick={() => this.handleStatusChange(User.getApproveStatus())}>Accept</Button>
              <Button variant="outlined" size="small" className={classes.reject} onClick={() => this.handleStatusChange(C.STATUS_REJECTED)}>Reject</Button>
            </ButtonGroup>
          );
      }
    } else {
      return (
        <ButtonGroup size="small" fullWidth>
          <Button variant="outlined" size="small" className={classes.accept} disabled>Accept</Button>
          <Button variant="outlined" size="small" className={classes.reject} disabled>Reject</Button>
        </ButtonGroup>
      );
    }
  }

  render() {
    return this.renderCurrentStatus();
  }
}

ApproveBar.defaultProps = {
  onChange: () => { },
  canApprove: true,
};

ApproveBar.propTypes = {
  onChange: PropTypes.func,
  polygons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  canApprove: PropTypes.bool,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(ApproveBar);
