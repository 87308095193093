import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../helper/misc';

window.google = window.google ? window.google : {};
const { google } = window;

const useStyles = (() => ({
  input: {
    backgroundColor: '#ffffff',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, 2px) scale(0.75)',
    },
  },
}));

class MapAddressBar extends React.Component {
  constructor() {
    super();

    this.state = {
      address: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { map } = this.props;

    if (prevProps.map !== map && map) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      const placeService = new google.maps.places.PlacesService(map);
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), {
        componentRestrictions: { country: 'us' },
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          this.setState({ address: place.formatted_address });
          map.fitBounds(place.geometry.viewport);
        } else if (place.name) {
          const regexLatLng = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
          if (regexLatLng.test(place.name.trim())) {
            const [lat, lng] = place.name.trim().split(',');
            this.onSearchLatLng({ lat, lng });
          } else {
            autocompleteService.getQueryPredictions({ input: place.name }, (variants) => {
              if (variants && variants.length) {
                const [variant] = variants;
                placeService.getDetails({ placeId: variant.place_id }, (placeDetails) => {
                  if (placeDetails && placeDetails.geometry) {
                    this.setState({ address: placeDetails.formatted_address });
                    map.fitBounds(placeDetails.geometry.viewport);
                  }
                });
              }
            });
          }
        }
      });
    }
  }

  onSearchLatLng(latLng) {
    const { onSearchLatLng } = this.props;
    onSearchLatLng(latLng);
  }

  render() {
    const { classes } = this.props;
    const { address } = this.state;

    return (
      <TextField
        variant="outlined"
        fullWidth
        name="address"
        label="Search Address"
        type="text"
        id="address"
        className={classes.input}
        value={address}
        onChange={(event) => { this.setState({ address: event.target.value }); }}
      />
    );
  }
}

MapAddressBar.defaultProps = {
  map: null,
  onSearchLatLng: () => {},
};

MapAddressBar.propTypes = {
  map: PropTypes.shape(),
  onSearchLatLng: PropTypes.func,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(MapAddressBar);
