import JWTDecode from 'jwt-decode';
import AuthAPI from './auth';
import * as C from '../constants';

export const THASOS_USERID = '5eb446af65344b04af7cc192';

class User {
  constructor() {
    this.token = null;
    this.details = null;
  }

  getDetails() {
    const token = AuthAPI.getToken();
    if (!this.token || !this.details || this.token !== token) {
      this.token = token;
      this.details = (token ? JWTDecode(token) : null);
    }
    return this.details;
  }

  getRole() {
    const details = this.getDetails();
    return details && details.role ? details.role : null;
  }

  getId() {
    const details = this.getDetails();
    return details && details.sub ? details.sub : null;
  }

  getAllowedALIStatuses() {
    let ret = null;
    switch (this.getRole() || '') {
      case C.ROLE_VERIFIER:
        ret = [C.STATUS_DRAFT, C.STATUS_DRAFT_REJECTED, C.STATUS_VERIFIED_MISMATCH];
        break;
      case C.ROLE_ADMIN:
      case C.ROLE_QA:
        ret = null;
        break;
      case C.ROLE_USER:
      default:
        ret = [C.STATUS_NONE, C.STATUS_REJECTED];
        break;
    }
    return ret;
  }

  isAdmin() {
    return (this.getRole() === C.ROLE_ADMIN);
  }

  getApproveStatus() {
    let ret;
    switch (this.getRole() || '') {
      case C.ROLE_ADMIN:
        ret = C.STATUS_VERIFIED_ADMIN;
        break;
      case C.ROLE_QA:
        ret = C.STATUS_VERIFIED_QA;
        break;
      default:
        break;
    }
    return ret;
  }

  isInRole(roles) {
    return roles && roles.indexOf(this.getRole()) >= 0;
  }

  canAccessALI(ali) {
    let result = false;
    const user = this.getDetails();
    const statuses = this.getAllowedALIStatuses();
    if (!statuses || statuses.indexOf(ali.polygonStatus) >= 0) {
      result = !(user.role === C.ROLE_VERIFIER && ali.polygonStatus === C.STATUS_VERIFIED_MISMATCH && ali.verifiers && ali.verifiers.indexOf(user.sub) >= 0);
    }
    return result;
  }

  canSeeOtherUsers() {
    return [C.ROLE_ADMIN, C.ROLE_QA].includes(this.getRole());
  }

  canSeeSpecialLists() {
    return [C.ROLE_ADMIN, C.ROLE_QA].includes(this.getRole());
  }

  canApprove() {
    return [C.ROLE_ADMIN, C.ROLE_QA].includes(this.getRole());
  }

  canLinkALI() {
    return [C.ROLE_ADMIN, C.ROLE_QA].includes(this.getRole());
  }

  canCreateALI() {
    return [C.ROLE_ADMIN, C.ROLE_QA].includes(this.getRole());
  }

  canEditCoordinates() {
    return [C.ROLE_ADMIN, C.ROLE_QA].includes(this.getRole());
  }

  canSeeStatusMessage() {
    return (this.getRole() === C.ROLE_USER);
  }

  canSeeGeofence(geofence) {
    let ret = false;

    switch (this.getRole() || '') {
      case C.ROLE_USER:
        ret = (C.ROLE_USER === geofence.properties.userRole);
        break;

      case C.ROLE_QA:
      case C.ROLE_ADMIN:
        ret = true;
        break;

      case C.ROLE_VERIFIER:
      default:
        ret = false;
        break;
    }

    return ret;
  }

  getNewGeofenceStatus() {
    switch (this.getRole() || '') {
      case C.ROLE_USER:
        return C.STATUS_DRAFT;
      case C.ROLE_ADMIN:
        return C.STATUS_VERIFIED_ADMIN;
      case C.ROLE_QA:
        return C.STATUS_VERIFIED_QA;
      default:
        return '';
    }
  }

  getNextGeofenceStatus(geofence) {
    let { status } = geofence.properties;
    if (this.getRole() === C.ROLE_USER && status === C.STATUS_REJECTED) status = C.STATUS_DRAFT_REJECTED;
    return status;
  }
}

export default new User();

export function getUserRoleIndex(role) {
  switch (role) {
    case C.ROLE_USER:
      return 0;
    case C.ROLE_VERIFIER:
      return 1;
    case C.ROLE_ADMIN:
      return 2;
    case C.ROLE_QA:
      return 3;
    default:
      return 4;
  }
}
