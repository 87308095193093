import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
import Admin from './pages/admin';
import User from './service/user';
import * as C from './constants';

const ProtectedRoute = ({
  component: Component, path, exact, roles,
}) => (
  <Route
    path={path}
    exact={exact}
    render={() => (
      (User.getRole() && (!roles || User.isInRole(roles)))
        ? <Component /> : <Redirect to={{ pathname: '/login' }} />
    )}
  />
);

ProtectedRoute.defaultProps = {
  exact: false,
  roles: null,
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

const AppRouter = () => (
  <Router>
    <div>
      <ProtectedRoute path="/" exact component={Home} />
      <ProtectedRoute path="/admin" component={Admin} roles={[C.ROLE_ADMIN]} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
    </div>
  </Router>
);

export default AppRouter;
