import axios from 'axios';
import Config from '../config';
import { error } from '../helper/misc';

class AuthService {
  constructor() {
    const me = this;
    this.storage = localStorage;
    this.axios = axios;

    this.setupTokenHeader();
    this.axios.defaults.validateStatus = (status) => {
      if (status === 401) {
        me.logOut();
        if (window.location.pathname !== '/login') window.location.href = '/login';
      }
      return status >= 200 && status < 300;
    };
  }

  login(credentials) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.post(`${Config.api_endpoint}auth/login`, credentials).then((result) => {
        if (result.data && result.data.token) {
          me.rememberToken(result.data.token);
          me.setupTokenHeader();
          resolve(result.data.token);
        } else {
          reject(new Error('Unexpected response from server'));
        }
      }).catch((err) => reject(error(err)));
    });
  }

  register(user) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.post(`${Config.api_endpoint}auth/register`, user)
        .then((result) => resolve(result.data))
        .catch((err) => reject(error(err)));
    });
  }

  logOut() {
    this.storage.removeItem('token');
  }

  getToken() {
    return this.storage.getItem('token');
  }

  rememberToken(token) {
    return this.storage.setItem('token', token);
  }

  setupTokenHeader() {
    const token = this.getToken();
    this.axios.defaults.headers.common.Authorization = (token ? `Bearer ${token}` : '');
  }
}

export default new AuthService();
