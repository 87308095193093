import React from 'react';
import UndoIcon from '@material-ui/icons/Undo';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withStylesPropTypes } from '../helper/misc';

const useStyles = (() => ({
  root: {
    zIndex: 99999999,
    maxHeght: '18px',
    position: 'absolute',
    fontWeight: '0.6rem',
    textTransform: 'none',
    backgroundColor: 'ghostwhite',
    lineHeight: '1px',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  },
}));

class UndoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUndoBtnClick() {
    const { onUndoPolygonMove } = this.props;
    onUndoPolygonMove();
  }

  render() {
    const {
      classes,
      btnText,
      posLeft,
      posTop,
    } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        variant="contained"
        className={classes.root}
        style={{ left: posLeft, top: posTop }}
        onClick={() => this.handleUndoBtnClick()}
        onKeyDown={() => this.handleUndoBtnClick()}
      >
        <UndoIcon />
        <span>
          {btnText}
        </span>
      </div>
    );
  }
}
UndoButton.defaultProps = {
  btnText: 'Undo Changes',
};

UndoButton.propTypes = {
  btnText: PropTypes.string,
  onUndoPolygonMove: PropTypes.func.isRequired,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(UndoButton);
