import React from 'react';
import './home.css';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { yellow } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import XLSX from 'xlsx';
import AQA from 'aqa';
import { polygon } from '@turf/helpers';
import { intersect, area } from '@turf/turf';
import _ from 'lodash';
import Map from '../components/map';
import LocationSearch from '../components/locationsearch';
import LocationProperties from '../components/locationproperties';
import 'react-reflex/styles.css';
import ALIAPI from '../service/aliapi';
import AdminAPI from '../service/adminapi';
import { withStylesPropTypes, downloadExcelFile, resolveProperty, getHideTrafficForReason, getConstructionFlag } from '../helper/misc';
import Config from '../config';
import User, { getUserRoleIndex, THASOS_USERID } from '../service/user';
import * as C from '../constants';
import AlertDialog from '../components/alertdialog';
import { COLOR_SPECIAL_1 } from '../helper/colorprovider';
import ValidationIssues from '../components/validationissues';

const drawerWidth = 240;

const HeaderSwitch = withStyles({
  switchBase: {
    color: yellow[300],
    '&$checked': {
      color: yellow[500],
    },
    '&$checked + $track': {
      backgroundColor: yellow[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = ((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    height: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
  },
  appBarSpacer: {
    flexGrow: 0,
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  menuSelect: {
    color: 'inherit',
    marginRight: theme.spacing(2),
    '&>.MuiSelect-icon': {
      color: 'inherit',
    },
    '&:before': {
      borderBottomColor: '#ffffff !important',
    },
    '&:after': {
      borderBottomColor: '#ffffff',
    },
  },
}));

const preparePolygonColors = (polygons, polygonProperties = {}) => {
  const ret = [];
  (polygons || []).forEach((p, i) => {
    if (!p.deleted) {
      let colorIndex = polygons.length;
      if (p.properties.userId === THASOS_USERID) colorIndex = COLOR_SPECIAL_1;
      ret.push({
        ...p, index: i, colorIndex, roleIndex: getUserRoleIndex(p.properties.userRole), ...polygonProperties,
      });
    }
  });
  return ret;
};

const preparePolygonsToDisplay = (ali, usersMap) => {
  const aliPolygons = ali.polygons || [];
  const polygons = preparePolygonColors(aliPolygons);
  if (User.canApprove()) {
    polygons.sort((a, b) => {
      let ret = a.roleIdx1 - a.roleIdx2;
      if (ret === 0) ret = (a.properties.userId || '').localeCompare(b.properties.userId || '');
      return ret;
    });
    let lastUserId = -1;
    let approvingPolygon = null;
    // let hasApproved = false;
    let colorIndex = -1;
    for (let i = 0; i < polygons.length; i += 1) {
      const p = polygons[i];
      if (p.properties.userId) {
        const user = usersMap[p.properties.userId];
        p.lastEdit = {
          user,
          timestamp: p.updatedAt || p.createdAt || '',
        };
      }
      // if (p.properties.status === C.STATUS_VERIFIED_ADMIN && p.properties.userId && p.properties.userId !== User.getId()) hasApproved = true;
      if (lastUserId !== p.properties.userId || p.properties.userId === THASOS_USERID) {
        lastUserId = p.properties.userId;
        p.siblings = [];
        p.canApprove = true;
        approvingPolygon = p;
        colorIndex += 1;
      }
      if (p.properties.userId !== THASOS_USERID) p.colorIndex = colorIndex;
      if (approvingPolygon) {
        approvingPolygon.siblings.push(p);
      }
    }
    /*
    if (hasApproved) {
      for (let i = 0; i < polygons.length; i += 1) {
        const p = polygons[i];
        if (p.siblings && p.properties.status !== C.STATUS_VERIFIED_ADMIN && p.properties.userId && p.properties.userId !== User.getId()) {
          p.canApprove = false;
        }
      }
    }
    */
  }

  // eslint-disable-next-line no-param-reassign
  ali.polygonsToDisplay = polygons;

  return ali;
};

const populatUserInfo = (list, usersMap) => {
  if (usersMap) {
    list.forEach((rec) => {
      if (rec.users) {
        const userList = [];
        Object.keys(rec.users).forEach((uid) => {
          const user = usersMap[uid];
          userList.push({ ...user, timestamp: new Date(rec.users[uid]) });
        });
        // eslint-disable-next-line no-param-reassign
        rec.users = userList;
      }
    });
  }
};

const getOverlapMessage = (ali, overlappedWithALI, type) => `${ali.listName}-${ali.address}'s ${type} polygon has been overlapped with ${overlappedWithALI.listName} -${overlappedWithALI.address}'s ${type} polygon`;
const isGeography = (ali) => ali.listID === C.LIST_ID.GEOGRAPHIES;
const isOrphan = (ali) => ali.listID === C.LIST_ID.ORPHAN || ali.ali_linked;
const isALI = (ali) => !isGeography(ali) && !isOrphan(ali);
const coBrandedAlis = (ali, nearByAli) => {
  let coBranded = false;
  if ((ali.coBranded && ali.coBrandedAli && (ali.coBrandedAli || []).includes(nearByAli.ali))
  && (nearByAli.coBranded && nearByAli.coBrandedAli && (nearByAli.coBrandedAli || []).includes(ali.ali))) {
    coBranded = true;
  }
  return coBranded;
};

const isWithInStoreALI = (ali, nearByAli) => {
  let withInStore = false;
  if (ali.withInStore && ali.withInStoreAli && (ali.withInStoreAli || []).includes(nearByAli.ali)) {
    withInStore = true;
  }
  // Need to decide after discussing it with Chris
  if (nearByAli.withInStore && nearByAli.withInStoreAli && (nearByAli.withInStoreAli || []).includes(ali.ali)) {
    withInStore = true;
  }
  return withInStore;
};

const sharingBordersALI = (ali, nearByAli) => (ali.touchingBordersWithAli || []).includes(nearByAli.ali)
  && (nearByAli.touchingBordersWithAli || []).includes(ali.ali);

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      propertiesOpen: true,
      ali: {},
      mapReady: false,
      mapBounds: null,
      mapMode: false,
      usersMap: {},
      lists: [],
      currentList: [],
      currentAreaList: [],
      noPlygonsList: [],
      thasosList: [],
      outlierList: [],
      searchResultList: undefined,
      selectedListId: undefined,
      alert: {
        open: false,
        title: '',
        text: '',
        buttons: [],
      },
      getTableDataCallback: null,
      linkALIMode: false,
      aliAreaSearch: undefined,
      aliAreaList: [],
      selectedPolygon: undefined,
      goldenSet: true,
      showSource: C.SHOW_SOURCE_ALL,
      validationIssues: undefined,
      nearByAlis: [],
      alisFound: [],
      alisNotFound: [],
      overlappedAlis: {},
      noOverlapsAlis: {},
      tab: undefined,
    };
  }

  componentDidMount() {
    const me = this;

    const fnLoadLists = (usersMap) => {
      this.loadNextDataPage((p) => new Promise((resolve, reject) => {
        ALIAPI.getLists(p)
          .then((data) => {
            populatUserInfo(data.docs, usersMap);
            resolve(data);
          })
          .catch((reason) => reject(reason));
      }), 'lists')
        .then(() => {
          if (User.canSeeSpecialLists()) {
            const { lists } = me.state;
            lists.unshift(
              { listID: C.LIST_ID.ORPHAN, listName: C.LIST_NAME[C.LIST_ID.ORPHAN] },
              { listID: C.LIST_ID.GEOGRAPHIES, listName: C.LIST_NAME[C.LIST_ID.GEOGRAPHIES] },
            );
            me.setState({
              lists: lists.slice(),
            });
          }
        });
      me.scheduleLockRefresh();
    };

    if (User.canSeeOtherUsers()) {
      AdminAPI.getUsers().then((users) => {
        const map = {};
        users.forEach((u) => {
          map[u.id] = u;
        });
        me.setState({
          usersMap: map,
        });
        fnLoadLists(map);
      });
    } else {
      fnLoadLists();
    }
  }

  onListSelected(id) {
    this.setState({
      selectedListId: id,
      searchResultList: undefined,
    }, () => {
      this.loadCurrentListLocations();
    });
  }

  onNoPolygonsSelected() {
    this.setState({
      noPlygonsList: [],
    });
    const { usersMap } = this.state;
    this.loadNextDataPage((p) => new Promise((resolve, reject) => {
      ALIAPI.getNoPolygonsList(p)
        .then((data) => {
          populatUserInfo(data.docs, usersMap);
          resolve(data);
        })
        .catch((reason) => reject(reason));
    }), 'noPlygonsList');
  }

  onThasosSelected() {
    this.setState({
      thasosList: [],
    });
    const { usersMap } = this.state;
    this.loadNextDataPage((p) => new Promise((resolve, reject) => {
      ALIAPI.getThasosPolygonsList(p)
        .then((data) => {
          populatUserInfo(data.docs, usersMap);
          resolve(data);
        })
        .catch((reason) => reject(reason));
    }), 'thasosList');
  }

  onOutlierSelected() {
    this.setState({
      outlierList: [],
    });
    const { usersMap } = this.state;
    this.loadNextDataPage((p) => new Promise((resolve, reject) => {
      ALIAPI.getOutlierPolygonsList(p)
        .then((data) => {
          populatUserInfo(data.docs, usersMap);
          resolve(data);
        })
        .catch((reason) => reject(reason));
    }), 'outlierList');
  }

  onALISelected(location) {
    const me = this;
    const {
      mapReady, usersMap, ali, linkALIMode,
    } = this.state;

    this.setState({
      overlappedAlis: {},
      noOverlapsAlis: {},
    });

    if (linkALIMode) {
      const newAli = {
        ...location,
        polygons: ali.polygons,
        property: ali.property || {},
        polygonStatus: ali.polygonStatus,
        polygonsToDisplay: ali.polygonsToDisplay,
        nearByAliPolygons: [],
        ali_linked: true,
        modified: true,
      };
      this.setState({
        ali: newAli,
        linkALIMode: false,
      });
    } else if (mapReady) {
      this.closeALI(location.ali);
      switch (location.listID) {
        case C.LIST_ID.GEOGRAPHIES:
          ALIAPI.getGeography(location.ali).then((data) => {
            me.setState({
              ali: preparePolygonsToDisplay(data, usersMap),
              propertiesOpen: true,
            });
          });
          break;
        case C.LIST_ID.ORPHAN:
          ALIAPI.getOrphanProperty(location.ali).then((data) => {
            me.setState({
              ali: preparePolygonsToDisplay(data, usersMap),
              propertiesOpen: true,
            });
          });
          break;
        default:
          ALIAPI.lockALI(location.ali).then((data) => {
            // eslint-disable-next-line no-param-reassign
            data = preparePolygonsToDisplay(data, usersMap);

            this.getNearbyAliPolygons(data).then(({ nearByAlis, nearByAliPolygons }) => {
              // eslint-disable-next-line no-param-reassign
              data.nearByAliPolygons = nearByAliPolygons;
              me.setState({
                nearByAlis,
                ali: data,
                propertiesOpen: true,
              });
            });
          }).catch(() => {
            ALIAPI.get(location.ali).then((data) => {
              me.setState({
                ali: {
                  ...preparePolygonsToDisplay(data, usersMap),
                  readOnly: true,
                },
                propertiesOpen: true,
              });
            });
          });
          break;
      }
    }
  }

  onALIChanged(ali) {
    const { usersMap } = this.state;
    this.setState({
      ali: preparePolygonsToDisplay(ali, usersMap),
      selectedPolygon: undefined,
    });
  }

  onMapSearchMode(mapMode) {
    this.setState({
      mapMode,
    });
    if (mapMode) this.loadCurrentAreaList();
  }

  onMapReady() {
    this.setState({
      mapReady: true,
    });
  }

  onMapIdle(bounds) {
    const { mapMode } = this.state;
    this.setState({
      mapBounds: bounds,
    });
    if (mapMode) this.loadCurrentAreaList();
  }

  onExcelExport() {
    this.setState({
      getTableDataCallback: (param) => {
        const data = param.data.map((row) => {
          const d = {};
          param.columns.forEach((c) => {
            let val = resolveProperty(row, c.dataKey);
            if (c.dataKey === 'hideTrafficForReason'){
              let flag = resolveProperty(row, "hideTraffic");
              if (!flag){
                val = ""
              } else {
                val = getHideTrafficForReason(val);
              }
            }
            if (c.dataKey === 'propertySet'){
              val = getConstructionFlag(row);
            }
            if (c.format) val = c.format(val);
            d[c.label] = val;
          });
          return d;
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, param.name);
        const xlsx = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'base64' });
        downloadExcelFile(`${param.name}.xlsx`, xlsx);

        this.setState({
          getTableDataCallback: null,
        });
      },
    });
  }

  async onCsvUpload(csv) {
    try {
      const { usersMap } = this.state;
      const formData = new FormData();
      formData.append('csv', csv);
      const resp = await ALIAPI.searchAlisCsv(formData);
      const { alisNotFound, alisFound } = resp;
      populatUserInfo(alisFound, usersMap);
      this.setState({
        alisFound,
        alisNotFound,
      });
    } catch (error) {
      this.showError(error);
    }
  }

  getNearbyAliPolygons(locationData) {
    const {
      latitude, longitude, ali, landlord,
    } = locationData;
    return new Promise((resolve, reject) => {
      ALIAPI.getAlisInRing(latitude, longitude, C.NEAR_BY_POLYGON_DISTANCE, C.INCLUDE_GEOFENCES).then((alis) => {
        // get all the nearby alis which have same landlord status as the selected ali and has geofences for it
        const nearByAlis = alis.filter((al) => al.ali !== ali && al.landlord === landlord && Array.isArray(al.polygons) && al.polygons.length && al.polygonStatus !== C.POLYGON_STATUS_NONE);
        populatUserInfo(nearByAlis, this.usersMap);

        // parse out the nearby alis polygons
        const nearByAliPolygons = [];
        nearByAlis.forEach((nearByAli) => {
          nearByAliPolygons.push(
            ...preparePolygonColors(nearByAli.polygons, { nonSelectable: true }),
          );
        });

        resolve({ nearByAlis, nearByAliPolygons });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  getOverlappingIssues(ali) {
    const { nearByAlis } = this.state;
    const overlappingIssues = [];
    const overlappedAlis = {};
    const noOverlapsAlis = {};
    const aliPolygons = (ali.polygons || []).filter((p) => !p.deleted);

    for (let pIdx = 0; pIdx < aliPolygons.length; pIdx += 1) {
      const p = aliPolygons[pIdx];

      for (let i = 0; i < nearByAlis.length; i += 1) {
        const nearByAli = nearByAlis[i];
        const nearByAliPolygons = (nearByAli.polygons || []).filter((pol) => !pol.deleted);

        for (let index = 0; index < nearByAliPolygons.length; index += 1) {
          const nearByPolygon = nearByAliPolygons[index];
          const intersection = intersect(polygon([p.coordinates]), polygon([nearByPolygon.coordinates]));

          // if both the ali polygon and nearByALI polygons are parking lot polygons
          // then polygon may be saved, but no warning message is displayed
          if (intersection) {
            if (!coBrandedAlis(ali, nearByAli) && !isWithInStoreALI(ali, nearByAli) && !sharingBordersALI(ali, nearByAli)) {
              if (p.properties.parkingLot && nearByPolygon.properties.parkingLot) {
                // eslint-disable-next-line no-continue
                continue;
              }

              if (!overlappedAlis[nearByAli.ali]) {
                // if both the polygons are landlord polygons
                if (ali.landlord && nearByAli.landlord) {
                  overlappingIssues.push({
                    level: C.ISSUES_LEVEL.WARNING,
                    message: getOverlapMessage(ali, nearByAli, C.POLYGON_TYPE_LANDLORD),
                    type: C.ISSUES_TYPE_OVERLAPPED_POLYGONS,
                    polygonType: C.POLYGON_TYPE_LANDLORD,
                    polygonIndex: pIdx,
                    nearByALIId: nearByAli.ali,
                    aliName: ali.listName,
                    nearByAliName: nearByAli.listName,
                  });
                  overlappedAlis[nearByAli.ali] = {
                    ali: ali.ali,
                    polygonType: C.POLYGON_TYPE_LANDLORD,
                    flag: '',
                  };
                }
                // for retailer polygons
                if (!ali.landlord && !nearByAli.landlord) {
                  overlappingIssues.push({
                    level: C.ISSUES_LEVEL.WARNING,
                    message: getOverlapMessage(ali, nearByAli, C.POLYGON_TYPE_RETAILER),
                    type: C.ISSUES_TYPE_OVERLAPPED_POLYGONS,
                    polygonType: C.POLYGON_TYPE_RETAILER,
                    overlapType: '',
                    nearByALIId: nearByAli.ali,
                    aliName: ali.listName,
                    nearByAliName: nearByAli.listName,
                  });

                  // keep reference to the retailer polygons that overlapped each other
                  overlappedAlis[nearByAli.ali] = {
                    ali: ali.ali,
                    polygonType: C.POLYGON_TYPE_RETAILER,
                    nearByPolygon,
                    flag: '',
                  };
                }
              }
            }
          } else {
            // if the polygons were overlaping each other earlier but now they are not for some reason.
            // in this case, we should remove the flag from the ALIs and save it
            if (coBrandedAlis(ali, nearByAli)) {
              noOverlapsAlis[nearByAli.ali] = {
                ali: ali.ali,
                overlapFlag: C.ALI_FLAG_COBRANDED,
              };
            }
            if (isWithInStoreALI(ali, nearByAli)) {
              noOverlapsAlis[nearByAli.ali] = {
                ali: ali.ali,
                overlapFlag: C.ALI_FLAG_WITHINSTORE,
              };
            }

            if (sharingBordersALI(ali, nearByAli)) {
              noOverlapsAlis[nearByAli.ali] = {
                ali: ali.ali,
                overlapFlag: C.ALI_FLAG_TOUCHING_BORDERS,
              };
            }
          }
        }
      }
    }
    this.setState({
      overlappedAlis,
      noOverlapsAlis,
    });
    return {
      overlappingIssues,
    };
  }

  handleOverlapPolygonFlagSelect(aliId, flag) {
    const { overlappedAlis } = this.state;
    const ali = overlappedAlis[aliId];
    overlappedAlis[aliId] = { ...ali, flag };
    this.setState({
      overlappedAlis,
    });
  }

  validateALI(ali, excludeAliSpecific) {
    return new Promise((resolve) => {
      const options = {
        excludeAliSpecific,
      };
      const issues = AQA.validate(ali, options);
      /* temporary switched off

      const { overlappingIssues } = this.getOverlappingIssues(ali);
      if (overlappingIssues) {
        issues.push(...overlappingIssues);
      }
      */
      if (issues.length) {
        const releaseAndResolve = (result) => {
          this.setState({
            validationIssues: undefined,
          });
          resolve(result);
        };
        this.setState({
          validationIssues: {
            list: issues,
            resolve: releaseAndResolve,
          },
        });
      } else {
        resolve(true);
      }
    });
  }

  loadCurrentListLocations() {
    const { selectedListId: listId } = this.state;

    this.setState({
      currentList: [],
      aliAreaSearch: undefined,
    });
    const { usersMap } = this.state;
    this.loadNextDataPage((p) => new Promise((resolve, reject) => {
      switch (listId) {
        case C.LIST_ID.GEOGRAPHIES:
          ALIAPI.getGeographies(p)
            .then((data) => {
              resolve(data);
            })
            .catch((reason) => reject(reason));
          break;
        case C.LIST_ID.ORPHAN:
          ALIAPI.getOrphanProperties(p)
            .then((data) => {
              resolve(data);
            })
            .catch((reason) => reject(reason));
          break;
        default:
          ALIAPI.getList(listId, p)
            .then((data) => {
              populatUserInfo(data.docs, usersMap);
              resolve(data);
            })
            .catch((reason) => reject(reason));
          break;
      }
    }), 'currentList');
  }

  searchByAli(ali) {
    this.setState({
      searchResultList: [],
      aliAreaSearch: undefined,
    });
    const { usersMap } = this.state;
    ALIAPI.searchByAli(ali).then((result) => {
      populatUserInfo(result, usersMap);
      this.setState({
        searchResultList: result,
      });
    });
  }

  loadNextDataPage(loader, datasetName, page) {
    const me = this;
    return new Promise((resolve) => {
      loader(page).then((pageData) => {
        let { [datasetName]: data } = this.state;
        if (page) {
          data.splice(pageData.offset, 0, ...pageData.docs);
        } else {
          data = pageData.docs;
        }
        const newState = {};
        newState[datasetName] = data;
        me.setState({
          [datasetName]: data.slice(),
        });
        if (pageData.offset + pageData.limit < pageData.total) {
          me.loadNextDataPage(loader, datasetName, (page || 0) + 1).then((d) => {
            resolve(d);
          });
        } else {
          resolve(data);
        }
      });
    });
  }

  loadCurrentAreaList() {
    const me = this;
    const { mapBounds, usersMap } = this.state;
    ALIAPI.getAlisInBounds(mapBounds).then((alis) => {
      populatUserInfo(alis, usersMap);
      me.setState({
        currentAreaList: alis,
      });
    });
  }

  loadALIAreaList(aliAreaSearch) {
    const me = this;
    const { usersMap } = this.state;
    if (aliAreaSearch && aliAreaSearch.ali) {
      if (aliAreaSearch.radius) {
        const { latitude, longitude } = aliAreaSearch.ali;
        ALIAPI.getAlisInRing(latitude, longitude, aliAreaSearch.radius).then((loadedAlis) => {
          const alis = loadedAlis.filter((a) => a.ali !== aliAreaSearch.ali.ali);
          populatUserInfo(alis, usersMap);
          me.setState({
            aliAreaList: alis,
          });
        });
      } else if (aliAreaSearch.showTenants) {
        const { ali } = aliAreaSearch.ali;
        ALIAPI.getAlisByParent(ali).then((loadedAlis) => {
          const alis = loadedAlis.filter((a) => a.ali !== aliAreaSearch.ali.ali);
          populatUserInfo(alis, usersMap);
          me.setState({
            aliAreaList: alis,
          });
        });
      }
    }
  }

  swithToALI(aliId) {
    ALIAPI.get(aliId)
      .then((ali) => this.onALIChanged(ali))
      .catch((err) => this.showError(err));
  }

  scheduleLockRefresh() {
    const me = this;
    setTimeout(() => {
      ALIAPI.getALILocks()
        .then((data) => {
          const map = data.reduce((result, v) => ({ ...result, [v.ali]: true }), {});
          me.scanAndUpdateAlisList((item) => {
            const currentLock = !!item.isLocked;
            const newLock = !!map[item.ali];
            return (currentLock !== newLock ? { ...item, isLocked: newLock } : null);
          });
        })
        .finally(() => {
          me.scheduleLockRefresh();
        });
    }, Config.status_refresh_timeout * 1000);
  }

  saveALI() {
    const me = this;
    const {
      ali,
      usersMap,
      nearByAlis,
    } = this.state;
    if (!ali.ali) {
      ali.listID = C.LIST_ID.ORPHAN;
    }

    if (isALI(ali)) {
      ali.locked = false;
      for (let i = 0; i < ali.polygons.length; i += 1) {
        ali.polygons[i].properties.status = User.getNextGeofenceStatus(ali.polygons[i]);
      }
      if (ali.property && ali.property.requestNames) ali.property.requestNames = ali.property.requestNames.filter((n) => !!n);
      this.validateALI(ali, false).then((valid) => {
        if (valid) {
          const overlappedAliPromises = [];
          const noOverlappedAliPromises = [];
          const { overlappedAlis, noOverlapsAlis } = this.state;

          // near by alis that don't overlap anymore but the overlap flag still exists
          // remove the overlap flags from near by ali and current ali
          (Object.keys(noOverlapsAlis) || []).forEach((key) => {
            const noOverlapsAli = noOverlapsAlis[key];
            const nearByAli = nearByAlis.filter((al) => al.ali === key)[0];

            if (nearByAli && noOverlapsAli.overlapFlag === C.ALI_FLAG_COBRANDED) {
              // clear out the co-branded flag from Near by ali
              nearByAli.coBrandedAliModified = true;
              nearByAli.coBranded = false;
              nearByAli.coBrandedAli = (nearByAli.coBrandedAli || []).filter((aliId) => aliId !== ali.ali);

              // remove the co-branded flags from current ALI
              ali.coBranded = false;
              ali.coBrandedAli = (ali.coBrandedAli || []).filter((aliId) => aliId !== nearByAli.ali);
              ali.coBrandedAliModified = true;

              noOverlappedAliPromises.push(ALIAPI.updateALI(nearByAli, {
                locked: false,
                coordinates: nearByAli.position_modified,
                property: nearByAli.ali_linked,
                coBrandedAliModified: nearByAli.coBrandedAliModified || false,
              }));
            }

            if (nearByAli && noOverlapsAli.overlapFlag === C.ALI_FLAG_WITHINSTORE) {
              // clear out within store
              nearByAli.withInStoreAliModified = true;
              if (nearByAli.withInStore) nearByAli.withInStore = false;
              nearByAli.withInStoreAli = (nearByAli.withInStoreAli || []).filter((aliId) => aliId !== ali.ali);

              if (ali.withInStore) ali.withInStore = false;
              ali.withInStoreAli = (ali.withInStoreAli || []).filter((aliId) => aliId !== nearByAli.ali);
              ali.withInStoreAliModified = true;

              noOverlappedAliPromises.push(ALIAPI.updateALI(nearByAli, {
                locked: false,
                coordinates: nearByAli.position_modified,
                property: nearByAli.ali_linked,
                withInStoreAliModified: nearByAli.withInStoreAliModified || false,
              }));
            }

            // remove the referenct touchingBorders ALI if there are any reference left
            if (nearByAli && noOverlapsAli.overlapFlag === C.ALI_FLAG_TOUCHING_BORDERS) {
              nearByAli.touchingBordersModified = true;
              nearByAli.touchingBordersWithAli = (nearByAli.touchingBordersWithAli || []).filter((aliId) => aliId !== ali.ali);
              noOverlappedAliPromises.push(ALIAPI.updateALI(nearByAli, {
                locked: false,
                coordinates: nearByAli.position_modified,
                property: nearByAli.ali_linked,
                touchingBordersModified: nearByAli.touchingBordersModified || false,
              }));
              ali.touchingBordersModified = true;
              ali.touchingBordersWithAli = (ali.touchingBordersWithAli || []).filter((aliId) => aliId !== nearByAli.ali);
            }
          });

          // if there are any overlapped Alis then following code will
          // set the co-brand/withinStore flag to ali and near by alis
          Object.keys(overlappedAlis || []).forEach((keyOverlappedAli) => {
            const overlappedAli = overlappedAlis[keyOverlappedAli];

            if (overlappedAli.polygonType === C.POLYGON_TYPE_RETAILER && overlappedAli.flag) {
              const oAli = nearByAlis.filter((al) => al.ali === keyOverlappedAli)[0];
              // co-branded ali
              if (overlappedAli.flag === C.ALI_FLAG_COBRANDED) {
                // set nearby ali's cobranded status and add cobranded ali ID
                if (_.isEmpty(oAli.coBrandedAli)) {
                  oAli.coBrandedAli = [ali.ali];
                } else if (_.isArray(oAli.coBrandedAli) && !oAli.coBrandedAli.includes(ali.ali)) {
                  oAli.coBrandedAli = [...oAli.coBrandedAli, ali.ali];
                }
                oAli.coBranded = true;
                oAli.coBrandedAliModified = true;

                // push the update ALI promise to promise array
                overlappedAliPromises.push(ALIAPI.updateALI(oAli, {
                  locked: false,
                  coordinates: oAli.position_modified,
                  property: oAli.ali_linked,
                  coBrandedAliModified: oAli.coBrandedAliModified || false,
                }));

                // for the current seclected ALI
                if (_.isEmpty(ali.coBrandedAli)) {
                  ali.coBrandedAli = [oAli.ali];
                } else if (_.isArray(ali.coBrandedAli) && !ali.coBrandedAli.includes(oAli.ali)) {
                  ali.coBrandedAli = [...ali.coBrandedAli, oAli.ali];
                }
                ali.coBranded = true;
                ali.coBrandedAliModified = true;
              }

              // For withInStore ALI, smaller polygons ALI's withInStore flag is set to true,
              // withInStoreALI field is populated with the larger polygons ALI ID
              if (overlappedAli.flag === C.ALI_FLAG_WITHINSTORE) {
                const oAliPolygon = overlappedAli.nearByPolygon;
                const oAliParkingLotStatus = (oAliPolygon.properties && oAliPolygon.properties.parkingLot) || false;
                const aliPolygon = ali.polygons.filter((p) => ((!!p.properties.parkingLot) !== oAliParkingLotStatus))[0];

                const areaOfOverlappedAli = oAliPolygon && oAliPolygon.coordinates ? area(polygon([oAliPolygon.coordinates])) : 0;
                const areaOfALI = aliPolygon && aliPolygon.coordinates ? area(polygon([aliPolygon.coordinates])) : 0;

                // populate the smaller ALI’s WithinStore flag to True,
                // and adds the ALI of the larger polygons as a value to the WithinStoreALI
                if (areaOfOverlappedAli > areaOfALI) {
                  ali.withInStoreAliModified = true;
                  ali.withInStore = true;

                  if (_.isEmpty(ali.withInStoreAli)) {
                    ali.withInStoreAli = [oAli.ali];
                  } else if (_.isArray(ali.withInStoreAli) && !ali.withInStoreAli.includes(oAli.ali)) {
                    ali.withInStoreAli = [...ali.withInStoreAli, oAli.ali];
                  }
                } else {
                  oAli.withInStoreAliModified = true;
                  oAli.withInStore = true;

                  if (_.isEmpty(oAli.withInStoreAli)) {
                    oAli.withInStoreAli = [ali.ali];
                  } else if (_.isArray(oAli.withInStoreAli) && !oAli.withInStoreAli.includes(ali.ali)) {
                    oAli.withInStoreAli = [...oAli.withInStoreAli, ali.ali];
                  }
                  // only overlapped Alis
                  overlappedAliPromises.push(ALIAPI.updateALI(oAli, {
                    locked: false,
                    coordinates: oAli.position_modified,
                    property: oAli.ali_linked,
                    withInStoreAliModified: oAli.withInStoreAliModified || false,
                  }));
                }
              }

              if (overlappedAli.flag === C.ALI_FLAG_TOUCHING_BORDERS) {
                // ali is updated separately, no need to push it into array
                ali.touchingBordersModified = true;
                if (_.isEmpty(ali.touchingBordersWithAli)) {
                  ali.touchingBordersWithAli = [oAli.ali];
                } else if (_.isArray(ali.touchingBordersWithAli) && !ali.touchingBordersWithAli.includes(oAli.ali)) {
                  ali.touchingBordersWithAli = [...ali.touchingBordersWithAli, oAli.ali];
                }

                oAli.touchingBordersModified = true;
                if (_.isEmpty(oAli.touchingBordersWithAli)) {
                  oAli.touchingBordersWithAli = [ali.ali];
                } else if (_.isArray(oAli.touchingBordersWithAli) && !oAli.touchingBordersWithAli.includes(ali.ali)) {
                  oAli.touchingBordersWithAli = [...oAli.touchingBordersWithAli, ali.ali];
                }
                overlappedAliPromises.push(ALIAPI.updateALI(oAli, {
                  locked: false,
                  coordinates: oAli.position_modified,
                  property: oAli.ali_linked,
                  touchingBordersModified: oAli.touchingBordersModified || false,
                }));
              }
            }
          });

          const nearByAliPromises = [...overlappedAliPromises, ...noOverlappedAliPromises];
          Promise.all(nearByAliPromises).then(() => {
            ALIAPI.updateProperty(ali).then((propertyData) => {
              ali.property = propertyData;
              ALIAPI.updateGeofence(ali).then(() => {
                if (ali.ali) {
                  ALIAPI.updateALI(ali, {
                    locked: true,
                    coordinates: ali.position_modified,
                    property: ali.ali_linked,
                    withInStoreAliModified: ali.withInStoreAliModified || false,
                    coBrandedAliModified: ali.coBrandedAliModified || false,
                    touchingBordersModified: ali.touchingBordersModified || false,
                    manualSqftModified: ali.manualSqftModified || false

                  }).then((updatedAli) => {
                    if(!!updatedAli){
                      if (!User.canAccessALI(updatedAli)) {
                        this.removeAliFromLists(updatedAli.ali);
                      } else {
                        populatUserInfo([updatedAli], usersMap);
                        const map = { [updatedAli.ali]: { ...updatedAli, isLocked: false } };
                        this.scanAndUpdateAlisList((item) => map[item.ali]);
                      }
                    }
                    me.setState({ ali: {} }); // close ALI
                  }).catch((err) => { me.showError(err); });
                } else {
                  me.setState({ ali: {} }); // close ALI
                }
              }).catch((err) => { me.showError(err); });
            }).catch((err) => { me.showError(err); });
          }).catch((err) => { me.showError(err); });
        }
      });
    } else if (isGeography(ali)) {
      ALIAPI.updateProperty(ali).then((propertyData) => {
        ali.property = propertyData;
        me.setState({ ali: {} });
      });
    } else if (isOrphan(ali)) {
      const linkedAli = ali.ali;
      delete ali.ali;

      ali.property.withAli = !!linkedAli;

      this.validateALI(ali, true).then((valid) => {
        if (valid) {
          ALIAPI.updateProperty(ali).then((propertyData) => {
            ali.property = propertyData;
            ALIAPI.updateGeofence(ali).then((updatedProperty) => {
              if (linkedAli) {
                ali.ali = linkedAli;
                ali.propertyId = ali.property._id;
                if (updatedProperty && updatedProperty.length) {
                  const lastProperty = updatedProperty[updatedProperty.length - 1];
                  if (lastProperty.polygons && lastProperty.polygons.length) ali.polygonStatus = C.STATUS_VERIFIED_ADMIN;
                }
                ALIAPI.updateALI(ali, {
                  locked: true, coordinates: ali.position_modified, property: true, polygonStatus: true,
                }).then((updatedAli) => {
                  populatUserInfo([updatedAli], usersMap);
                  const map = { [updatedAli.ali]: { ...updatedAli, isLocked: false } };
                  this.scanAndUpdateAlisList((item) => map[item.ali]);
                  me.setState({ ali: {} });
                });
              } else if (updatedProperty && updatedProperty.length) {
                const map = {
                  [updatedProperty[0]._id]: {
                    ...ali,
                    ali: updatedProperty[0]._id,
                    address: updatedProperty[0].name,
                    storeName: (updatedProperty[0].requestFlag ? updatedProperty[0].requestName : null),
                  },
                };
                this.scanAndUpdateAlisList((item) => map[item.ali]);
                me.setState({ ali: {} });
              }
            });
          });
        }
      });
    }
  }

  closeALI(newAli) {
    const me = this;
    const { ali } = this.state;
    if (ali && isALI(ali) && ali.ali && ali.ali !== newAli) {
      ALIAPI.lockALI(ali.ali, false).then(() => {
        me.scanAndUpdateAlisList((item) => (item.ali === ali.ali ? { ...item, isLocked: false } : null));
      });
    }
    this.setState({
      ali: {},
      linkALIMode: false,
      selectedPolygon: undefined,
      nearByAlis: [],
      overlappedAlis: [],
    });
  }

  scanAndUpdateAlisList(callback) {
    const me = this;
    const updatedState = {};
    let anyUpdated = false;
    ['currentList', 'currentAreaList'].forEach((listName) => {
      const { [listName]: list } = me.state;
      let updated = false;

      for (let idx = 0; idx < list.length; idx += 1) {
        const updatedItem = callback(list[idx]);
        if (updatedItem) {
          list[idx] = updatedItem;
          updated = true;
        }
      }

      if (updated) {
        anyUpdated = true;
        updatedState[listName] = list.slice();
      }
    });

    if (Object.keys(updatedState).length) {
      this.setState(updatedState);
    }

    return anyUpdated;
  }

  removeAliFromLists(ali) {
    const me = this;
    const updatedState = {};
    ['currentList', 'currentAreaList'].forEach((listName) => {
      const { [listName]: list } = me.state;

      const idx = list.findIndex((v) => v.ali === ali);
      if (idx >= 0) {
        const updatedList = list.slice();
        updatedList.splice(idx, 1);
        updatedState[listName] = updatedList;
      }
    });
    if (Object.keys(updatedState).length) {
      this.setState(updatedState);
    }
  }

  handleDrawerVisibility(open) {
    this.setState({
      propertiesOpen: open,
    });
  }

  handleALIAreaSearch(params) {
    this.setState({
      aliAreaSearch: params,
      aliAreaList: [],
    });
    if (params) this.loadALIAreaList(params);
  }

  handleALIAreaSearchExit(ali) {
    this.handleALIAreaSearch();
    this.onALISelected(ali);
  }

  handlePolygonSelected(indexes) {
    this.setState({
      selectedPolygon: indexes,
    });
  }

  handleALISearch(ali) {
    if (ali && ali.length) {
      this.searchByAli(ali);
    } else {
      this.setState({
        searchResultList: undefined,
      });
    }
  }

  handleGoldenSetChange(event) {
    this.setState({
      goldenSet: event.target.checked,
    });
  }

  handleShowSourceChange(event) {
    this.setState({
      showSource: event.target.value,
    });
  }

  showAlert(title, text, buttons, handler) {
    const me = this;
    const { alert } = this.state;

    this.setState({
      alert: {
        title,
        text,
        buttons,
        open: true,
        handler: (index) => {
          me.setState({ alert: { ...alert, open: false } });
          (handler || (() => {}))(index);
        },
      },
    });
  }

  showError(err) {
    this.showAlert('Error', err.message || 'Internal Error', ['OK']);
  }

  showOverlapRisks(overlapRisksAlis) {
    if (!_.isEmpty(overlapRisksAlis)) {
      const { ali } = this.state;
      ali.canDrawOverlaps = !ali.canDrawOverlaps;
      this.setState({
        currentAreaList: overlapRisksAlis,
        tab: ali.canDrawOverlaps ? C.TABS.TAB_SEARCH_THIS_AREA : C.TABS.TAB_VIEW_BY_LIST,
      });
      this.onALIChanged({ ...ali });
    }
  }

  handleResetOverlap(value) {
    this.setState({
      tab: value,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      propertiesOpen,
      ali,
      lists,
      currentList,
      currentAreaList,
      noPlygonsList,
      thasosList,
      outlierList,
      aliAreaList,
      aliAreaSearch,
      searchResultList,
      alert,
      getTableDataCallback,
      linkALIMode,
      selectedPolygon,
      goldenSet,
      showSource,
      validationIssues,
      nearByAlis,
      alisFound,
      alisNotFound,
      tab,
    } = this.state;

    const role = User.getRole().substring(0, 1).toUpperCase();

    return (
      <div className={classes.root}>
        <AppBar position="absolute" className={clsx(classes.appBar, propertiesOpen && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => { this.handleDrawerVisibility(true); }}
              className={clsx(classes.menuButton, propertiesOpen && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Aggdata Integrated Geofencer (
              {role}
              )
            </Typography>
            { false && (
            <Select
              color="primary"
              value={showSource}
              variant="standard"
              size="small"
              className={classes.menuSelect}
              onChange={(event) => this.handleShowSourceChange(event)}
            >
              {Object.keys(C.SHOW_SOURCES).map((source) => (
                <MenuItem key={source} value={source}>
                  {C.SHOW_SOURCES[source]}
                </MenuItem>
              ))}
            </Select>
            ) }
            <FormControlLabel
              control={<HeaderSwitch checked={goldenSet} color="primary" onChange={(event) => this.handleGoldenSetChange(event)} name="goldenSet" />}
              label="Show Golden Set Only"
            />
            {
              User.isAdmin() && (<Button href="/admin" className={classes.menuLink}>ADMIN</Button>)
            }
            {
              User.isAdmin() && (<Button className={classes.menuLink} onClick={() => this.onExcelExport()}>EXCEL EXPORT</Button>)
            }
            <Button className={classes.menuLink} href="/login">SIGN OUT</Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          classes={{
            paper: clsx(classes.drawerPaper, !propertiesOpen && classes.drawerPaperClose),
          }}
          open={propertiesOpen}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={() => { this.handleDrawerVisibility(false); }}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <LocationProperties
            ali={ali}
            nearByAlis={nearByAlis}
            createALILists={lists.filter((l) => l.listID >= 10000000)}
            editablePolygons={!isGeography(ali)}
            currentList={currentList}
            currentAreaList={currentAreaList}
            onALIChanged={(newAli) => this.onALIChanged(newAli)}
            onSaveALI={() => this.saveALI()}
            onCloseALI={() => this.closeALI()}
            onSwitchToALI={(newAli) => this.swithToALI(newAli)}
            onShowOverlapRisks={(overlapRisksAlis) => this.showOverlapRisks(overlapRisksAlis)}
            onAlert={({
              title, text, buttons, handler,
            }) => this.showAlert(title, text, buttons, handler)}
            onLinkALI={() => this.setState({ linkALIMode: true })}
            onALIAreaSearch={(params) => this.handleALIAreaSearch(params)}
            selectedPolygon={selectedPolygon}
            onPolygonSelected={(indexes) => this.handlePolygonSelected(indexes)}
          />
        </Drawer>
        <div className={classes.content}>
          <ReflexContainer orientation="horizontal">
            <ReflexElement className={classes.appBarSpacer} flex={0} />
            <ReflexElement flex={1} minSize={200}>
              <Map
                ali={ali}
                editable={!isGeography(ali)}
                aliAreaList={aliAreaSearch && aliAreaList}
                onALIChanged={(newAli) => this.onALIChanged(newAli)}
                onMapReady={() => this.onMapReady()}
                onMapIdle={(bounds) => this.onMapIdle(bounds)}
                onPolygonSelected={(indexes) => this.handlePolygonSelected(indexes)}
                onALISearch={(search) => this.handleALISearch(search)}
                selectedPolygon={selectedPolygon}
              />
            </ReflexElement>
            <ReflexSplitter propagate />
            <ReflexElement flex={0.5} minSize={260}>
              <LocationSearch
                onListSelected={(id) => { this.onListSelected(id); }}
                onLocationSelected={(location) => { this.onALISelected(location); }}
                onMapSearchMode={(map) => { this.onMapSearchMode(map); }}
                onNoPolygonsTab={() => this.onNoPolygonsSelected()}
                onThasosTab={() => this.onThasosSelected()}
                onOutlierTab={() => this.onOutlierSelected()}
                onCsvUpload={(csv) => this.onCsvUpload(csv)}
                alisFound={alisFound}
                alisNotFound={alisNotFound}
                lists={lists}
                currentList={currentList}
                searchResultList={searchResultList}
                currentAreaList={currentAreaList}
                noPlygonsList={noPlygonsList}
                thasosList={thasosList}
                outlierList={outlierList}
                aliAreaList={aliAreaList}
                aliAreaSearch={aliAreaSearch}
                getTableDataCallback={getTableDataCallback}
                linkALIMode={linkALIMode}
                goldenSet={goldenSet}
                showSource={showSource}
                tab={tab}
                onExitALIAreaSearch={(a) => this.handleALIAreaSearchExit(a)}
              />
            </ReflexElement>
          </ReflexContainer>
        </div>
        <AlertDialog dialog={alert} />
        {
          validationIssues && (
            <ValidationIssues
              issues={validationIssues.list}
              onOK={() => validationIssues.resolve(true)}
              onClose={() => validationIssues.resolve(false)}
              onOverlapPolygonFlagSelect={(aliId, flag) => this.handleOverlapPolygonFlagSelect(aliId, flag)}
              onPolygonSelect={(polygonIndex) => this.handlePolygonSelected({ polygonIndex })}
              onVertexSelect={(polygonIndex, vertexIndex) => this.handlePolygonSelected({ polygonIndex, vertexIndex })}
            />
          )
        }
      </div>
    );
  }
}

Home.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(Home);
