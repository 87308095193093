import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdminAPI from '../service/adminapi';
import * as C from '../constants';

class UserDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      user: {},
      validator: {},
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user !== prevProps.user) {
      this.onUserChanged(user);
    }
  }

  onUserChanged(user) {
    const me = this;

    const newUser = { ...user };
    this.setState({
      open: !!user,
      user: newUser,
      error: null,
    }, () => {
      ['name', 'email', 'password', 'role'].forEach((name) => {
        const value = newUser[name];
        me.validateField(name, value);
      });
    });
  }

  closeDialog() {
    const { onClose } = this.props;
    this.setState({
      open: false,
      error: null,
    });
    onClose();
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;

    this.setState({ user }, () => { this.validateField(name, value); });
  }

  validateField(name, value) {
    const { validator, user } = this.state;
    const newUser = !user.id;
    switch (name) {
      case 'name': validator.name = true; break;
      case 'role': validator.role = (value && value.length); break;
      case 'email': validator.email = (value && value.length); break;
      case 'password': validator.password = !newUser || (value && value.length); break;
      default: break;
    }
    validator.form = validator.name && validator.email && validator.password && validator.role;
    this.setState({ validator });
  }

  handleOK() {
    const me = this;
    const { onOK } = this.props;
    const { user } = this.state;

    AdminAPI.updateUser(user).then(() => {
      onOK(user);
      me.closeDialog();
    }).catch((error) => {
      me.setState({
        error: error.message,
      });
    });
  }

  handleCancel() {
    this.closeDialog();
  }

  render() {
    const {
      open, user, validator, error,
    } = this.state;

    const newUser = !user.id;
    return (
      <Dialog
        open={open}
        onClose={() => this.handleCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ (newUser ? 'Create User' : 'Edit User') }</DialogTitle>
        <DialogContent>
          { (error && <Alert severity="error">{error}</Alert>) }
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                fullWidth
                id="name"
                label="Name"
                autoFocus
                value={user.name || ''}
                onChange={(event) => this.handleUserInput(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={user.email || ''}
                onChange={(event) => this.handleUserInput(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required={newUser}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={user.password || ''}
                onChange={(event) => this.handleUserInput(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                select
                name="role"
                label="Role"
                id="role"
                value={user.role || ''}
                onChange={(event) => this.handleUserInput(event)}
              >
                {C.USER_ROLES.map((v) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleOK()} color="primary" disabled={!validator.form}>OK</Button>
          <Button onClick={() => this.handleCancel()} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UserDialog.defaultProps = {
  onOK: () => {},
  onClose: () => {},
  user: {},
};

UserDialog.propTypes = {
  onOK: PropTypes.func,
  onClose: PropTypes.func,
  user: PropTypes.shape(),
};

export default UserDialog;
