import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableView from './tableview';
import { withStylesPropTypes } from '../helper/misc';
import User from '../service/user';
import * as C from '../constants';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabBar: {
    flexGrow: '0',
  },
  tab: {
    display: 'none',
    padding: '5px',
    flexGrow: '1',
    flexDirection: 'column',
  },
  activeTab: {
    display: 'flex',
  },
  center: {
    textAlign: 'center',
  },
  lockedRow: {
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
  },
  linkableRow: {
    fontWeight: 'bold',
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  displayNone: {
    display: 'none',
  },
  warningContainer: {
    margin: '8px 0',
    fontSize: '1rem',
  },
  csvContainer: {
    margin: '5px 0',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  csvUploadLabel: {
    padding: '5px',
  },
}));

const listColumns = [
  { label: 'Category', dataKey: 'category' },
  { label: 'List ID', dataKey: 'listID', numeric: true },
  { label: 'List Name', dataKey: 'listName' },
  { label: 'US Locations', dataKey: 'usLocations', numeric: true },
  { label: 'Aggdata Verified Locations', dataKey: 'aggDataVerifiedLocations', numeric: true },
  { label: 'Aggdata Verified %', dataKey: 'aggDataVerifiedPercent', numeric: true },
  { label: 'Polygon Matches', dataKey: 'polygonMatches', numeric: true },
  { label: 'Polygon Match %', dataKey: 'polygonMatchPercent', numeric: true },
  { label: 'Verified Polygon Matches', dataKey: 'verifiedPolygonMatches', numeric: true },
];

const fnFormatSqftColumn = (data) => {
  if (data && data.rowData) {
    return data.rowData.manualSqft? data.rowData.manualSqft + '*': data.rowData.squareFootage 
  }
  return '-';
}

const aliColumns = [
  { label: 'Category', dataKey: 'category' },
  { label: 'List ID', dataKey: 'listID', numeric: true },
  { label: 'List Name', dataKey: 'listName' },
  { label: 'ALI', dataKey: 'ali' },
  { label: 'Polygon Status', dataKey: 'polygonStatus' },
  { label: 'Store Name', dataKey: 'storeName' },
  { label: 'Address', dataKey: 'address' },
  { label: 'City', dataKey: 'city' },
  { label: 'State', dataKey: 'state' },
  { label: 'ZIP', dataKey: 'zip' },
  { label: 'Geo Accuracy', dataKey: 'geoAccuracy' },
  { label: 'SQFT', dataKey: 'squareFootage', numeric: true, getter: (data) => fnFormatSqftColumn(data) },
  { label: 'Manual SQFT', dataKey: 'manualSqft' },
  { label: 'SC', dataKey: 'sc' },
];

const rocColumns = [
  { label: 'Type', dataKey: 'statusSimple' },
  { label: 'Date Effective', dataKey: 'dateEffective', sortKey: 'dateEffectiveSort' },
];

class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
    };

    if (User.canSeeOtherUsers()) {
      const fnFormatUserColumn = (data) => {
        if (data && data.length) {
          data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
          return data[0].email + (data.length > 1 ? `, ... (${(data.length - 1)} more)` : '');
        }
        return '';
      };
      listColumns.push(
        {
          label: 'User',
          dataKey: 'users',
          filterChildKey: 'email',
          format: (data) => fnFormatUserColumn(data),
        },
      );

      aliColumns.push(
        {
          label: 'User',
          dataKey: 'users',
          filterChildKey: 'email',
          format: (data) => fnFormatUserColumn(data),
        },
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { linkALIMode, searchResultList, tab } = this.props;
    if (prevProps.linkALIMode !== linkALIMode && linkALIMode) {
      this.onLinkALIMode();
    }
    if (prevProps.searchResultList !== searchResultList) {
      this.onSearchResultListUpdated(searchResultList);
    }
    if (prevProps.tab !== tab) {
      this.onShowOverlapRiskUpdated(tab);
    }
  }

  onLinkALIMode() {
    this.setState({
      currentTab: C.TABS.TAB_SEARCH_THIS_AREA,
    });
    const { onMapSearchMode } = this.props;
    onMapSearchMode(true);
  }

  onSearchResultListUpdated(searchResultList) {
    if (searchResultList) {
      this.setState({
        currentTab: C.TABS.TAB_VIEW_BY_LIST,
      });
    }
  }

  onShowOverlapRiskUpdated(tab) {
    if (!_.isUndefined(tab) && tab >= 0) {
      this.setState({
        currentTab: tab,
      });
    }
  }

  onListSelected(list) {
    const { onListSelected } = this.props;
    onListSelected(list.listID);
    this.setState({
      currentTab: C.TABS.TAB_VIEW_BY_LIST,
    });
  }

  onLocationSelected(location) {
    const { onLocationSelected, linkALIMode } = this.props;
    if (!linkALIMode || location.polygonStatus === C.STATUS_NONE) onLocationSelected(location);
  }

  getALITableRowClass(row) {
    const { linkALIMode, classes } = this.props;
    const ret = { [classes.lockedRow]: row && row.isLocked };
    if (linkALIMode && row) {
      if (row.polygonStatus === C.STATUS_NONE) ret[classes.linkableRow] = true;
      else ret[classes.lockedRow] = true;
    }
    return ret;
  }

  handleCurrentTabChange(_event, value) {
    this.setState({
      currentTab: value,
    });

    const {
      onMapSearchMode, onNoPolygonsTab, onThasosTab, onOutlierTab,
    } = this.props;
    onMapSearchMode(value === C.TABS.TAB_SEARCH_THIS_AREA);
    if (value === C.TABS.TAB_NO_POLYGONS) onNoPolygonsTab();
    if (value === C.TABS.TAB_THASOS) onThasosTab();
    if (value === C.TABS.TAB_OUTLIER) onOutlierTab();
  }

  handleChangeCsv(event) {
    const csvFile = event.target.files[0];
    const { onCsvUpload } = this.props;
    onCsvUpload(csvFile);
  }

  render() {
    const {
      classes,
      lists,
      currentList,
      searchResultList,
      currentAreaList,
      noPlygonsList,
      thasosList,
      outlierList,
      aliAreaList,
      aliAreaSearch,
      goldenSet,
      showSource,
      getTableDataCallback,
      onExitALIAreaSearch,
      alisFound,
      alisNotFound,
    } = this.props;
    const { currentTab } = this.state;
    const showRocData = (showSource !== C.SHOW_SOURCE_AGGDATA);
    let aliColumnsEffective = aliColumns;

    if (showRocData) {
      aliColumnsEffective = [...aliColumns, ...rocColumns];
    }

    const filterLocations = (array) => {
      let ret = array;
      if (goldenSet) ret = ret.filter((l) => l.goldenSet);
      switch (showSource) {
        case C.SHOW_SOURCE_ROC:
          ret = ret.filter((l) => l.source === 'roc');
          break;
        case C.SHOW_SOURCE_AGGDATA:
          ret = ret.filter((l) => l.source !== 'roc');
          break;
        default:
          break;
      }
      return ret;
    };

    const getTableDataCallbackExt = getTableDataCallback ? (params) => {
      const result = { ...params };
      const fnAddExtraColumns = () => {
        result.columns.push({ label: 'Latitude', dataKey: 'latitude' });
        result.columns.push({ label: 'Longitude', dataKey: 'longitude' });
        result.columns.push({ label: 'Parent ALI', dataKey: 'parent.ali' });
        result.columns.push({ label: 'Parent Store Name', dataKey: 'parent.storeName' });
        result.columns.push({ label: 'Parent List Name', dataKey: 'parent.listName' });
        result.columns.push({ label: 'HideTraffic', dataKey: 'hideTraffic' });
        result.columns.push({ label: 'HideFromApp', dataKey: 'hideFromApp' });
        result.columns.push({ label: 'LastUpdated', dataKey: 'lastUpdate' });
        result.columns.push({ label: 'hideTrafficFor', dataKey: 'hideTrafficForReason' });
        result.columns.push({ label: 'constructionFlag', dataKey: 'propertySet' });
      };
      switch (currentTab) {
        case C.TABS.TAB_SEARCH_BY_LIST:
          result.name = 'Search by List';
          break;
        case C.TABS.TAB_VIEW_BY_LIST:
          result.name = 'View by List';
          fnAddExtraColumns();
          break;
        case C.TABS.TAB_SEARCH_THIS_AREA:
          result.name = 'Search this Area';
          fnAddExtraColumns();
          break;
        case C.TABS.TAB_UPLOAD_ALIS:
          result.name = 'Upload ALIs';
          fnAddExtraColumns();
          break;
        default:
          break;
      }
      const usersIdx = result.columns.findIndex((c) => c.dataKey === 'users');
      if (usersIdx >= 0) {
        result.columns[usersIdx] = {
          ...result.columns[usersIdx],
          format: (data) => data && data.length && data.map((u) => u.email).join(', '),
        };
      }
      getTableDataCallback(result);
    } : undefined;

    return (
      <div className={classes.root}>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          onChange={(event, value) => this.handleCurrentTabChange(event, value)}
          className={classes.tabBar}
        >
          <Tab label="Search by List" />
          <Tab label="View by List" />
          <Tab label="Search this Area" />
          <Tab label="Upload ALIs" />
        </Tabs>

        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_SEARCH_BY_LIST && classes.activeTab)}>
          <TableView
            data={lists}
            columns={
                            listColumns.map((c) => {
                              let column = null;
                              switch (c.dataKey) {
                                case 'category': column = { ...c, width: 260, flexGrow: 1 }; break;
                                case 'listID': column = { ...c, width: 100 }; break;
                                case 'listName': column = { ...c, width: 300, flexGrow: 2 }; break;
                                case 'users': column = { ...c, width: 200, flexGrow: 1 }; break;
                                case 'usLocations': column = {
                                  ...c, width: 120, headerClassName: classes.center, className: classes.center, dataKey: goldenSet ? 'usLocationsGoldenSet' : 'usLocations',
                                }; break;
                                case 'aggDataVerifiedLocations': column = {
                                  ...c, width: 190, headerClassName: classes.center, className: classes.center, dataKey: goldenSet ? 'aggDataVerifiedLocationsGoldenSet' : 'aggDataVerifiedLocations',
                                }; break;
                                case 'aggDataVerifiedPercent': column = {
                                  ...c, width: 140, headerClassName: classes.center, className: classes.center, dataKey: goldenSet ? 'aggDataVerifiedPercentGoldenSet' : 'aggDataVerifiedPercent',
                                }; break;
                                case 'polygonMatches': column = {
                                  ...c, width: 100, headerClassName: classes.center, className: classes.center, dataKey: goldenSet ? 'polygonMatchesGoldenSet' : 'polygonMatches',
                                }; break;
                                case 'polygonMatchPercent': column = {
                                  ...c, width: 120, headerClassName: classes.center, className: classes.center, dataKey: goldenSet ? 'polygonMatchPercentGoldenSet' : 'polygonMatchPercent',
                                }; break;
                                case 'verifiedPolygonMatches': column = {
                                  ...c, width: 190, headerClassName: classes.center, className: classes.center, dataKey: goldenSet ? 'verifiedPolygonMatchesGoldenSet' : 'verifiedPolygonMatches',
                                }; break;
                                default: column = { ...c };
                              }
                              return column;
                            })
                        }
            onRowClick={(e) => { this.onListSelected(e.rowData); }}
            getTableDataCallback={currentTab === C.TABS.TAB_SEARCH_BY_LIST && getTableDataCallbackExt}
          />
        </div>
        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_VIEW_BY_LIST && classes.activeTab)}>
          <TableView
            data={filterLocations(aliAreaSearch && aliAreaList ? aliAreaList : (searchResultList || currentList))}
            stickyRow={aliAreaSearch && aliAreaList ? aliAreaSearch.ali : undefined}
            columns={
              aliColumnsEffective.map((c) => {
                let column = null;
                switch (c.dataKey) {
                  case 'category': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'listID': column = { ...c, width: 80 }; break;
                  case 'listName': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'ali': column = { ...c, width: 130 }; break;
                  case 'polygonStatus': column = { ...c, width: 110 }; break;
                  case 'storeName': column = { ...c, width: 220 }; break;
                  case 'address': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'city': column = { ...c, width: 110, flexGrow: 0.4 }; break;
                  case 'state': column = { ...c, width: 80 }; break;
                  case 'zip': column = { ...c, width: 80 }; break;
                  case 'geoAccuracy': column = { ...c, width: 115 }; break;
                  case 'squareFootage': column = { ...c, width: 90 }; break;
                  case 'sc': column = { ...c, width: 70 }; break;
                  case 'users': column = { ...c, width: 130, flexGrow: 1 }; break;
                  case 'statusSimple': column = { ...c, width: 70 }; break;
                  case 'dateEffective': column = { ...c, width: 100 }; break;
                  default: column = { ...c }; break;
                }
                return column;
              })
          }
            onRowClick={(e) => { this.onLocationSelected(e.rowData); }}
            onStickyRowClick={(row) => { onExitALIAreaSearch(row); }}
            getRowClassName={(row) => this.getALITableRowClass(row)}
            getTableDataCallback={currentTab === C.TABS.TAB_VIEW_BY_LIST && getTableDataCallbackExt}
          />
        </div>
        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_SEARCH_THIS_AREA && classes.activeTab)}>
          <TableView
            data={filterLocations(currentAreaList)}
            columns={
              aliColumnsEffective.map((c) => {
                let column = null;
                switch (c.dataKey) {
                  case 'category': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'listID': column = { ...c, width: 80 }; break;
                  case 'listName': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'ali': column = { ...c, width: 130 }; break;
                  case 'polygonStatus': column = { ...c, width: 110 }; break;
                  case 'storeName': column = { ...c, width: 220 }; break;
                  case 'address': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'city': column = { ...c, width: 110, flexGrow: 0.4 }; break;
                  case 'state': column = { ...c, width: 80 }; break;
                  case 'zip': column = { ...c, width: 80 }; break;
                  case 'geoAccuracy': column = { ...c, width: 115 }; break;
                  case 'squareFootage': column = { ...c, width: 90 }; break;
                  case 'sc': column = { ...c, width: 70 }; break;
                  case 'users': column = { ...c, width: 130, flexGrow: 1 }; break;
                  case 'statusSimple': column = { ...c, width: 70 }; break;
                  case 'dateEffective': column = { ...c, width: 100 }; break;
                  default: column = { ...c }; break;
                }
                return column;
              })
            }
            onRowClick={(e) => { this.onLocationSelected(e.rowData); }}
            getRowClassName={(row) => this.getALITableRowClass(row)}
            getTableDataCallback={currentTab === C.TABS.TAB_SEARCH_THIS_AREA && getTableDataCallbackExt}
          />
        </div>
        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_NO_POLYGONS && classes.activeTab)}>
          <TableView
            data={filterLocations(noPlygonsList)}
            columns={
              aliColumnsEffective.map((c) => {
                let column = null;
                switch (c.dataKey) {
                  case 'category': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'listID': column = { ...c, width: 80 }; break;
                  case 'listName': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'ali': column = { ...c, width: 130 }; break;
                  case 'polygonStatus': column = { ...c, width: 110 }; break;
                  case 'storeName': column = { ...c, width: 220 }; break;
                  case 'address': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'city': column = { ...c, width: 110, flexGrow: 0.4 }; break;
                  case 'state': column = { ...c, width: 80 }; break;
                  case 'zip': column = { ...c, width: 80 }; break;
                  case 'geoAccuracy': column = { ...c, width: 115 }; break;
                  case 'squareFootage': column = { ...c, width: 90 }; break;
                  case 'sc': column = { ...c, width: 70 }; break;
                  case 'users': column = { ...c, width: 130, flexGrow: 1 }; break;
                  case 'statusSimple': column = { ...c, width: 70 }; break;
                  case 'dateEffective': column = { ...c, width: 100 }; break;
                  default: column = { ...c }; break;
                }
                return column;
              })
            }
            onRowClick={(e) => { this.onLocationSelected(e.rowData); }}
            getRowClassName={(row) => this.getALITableRowClass(row)}
            getTableDataCallback={currentTab === C.TABS.TAB_NO_POLYGONS && getTableDataCallbackExt}
          />
        </div>
        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_THASOS && classes.activeTab)}>
          <TableView
            data={filterLocations(thasosList)}
            columns={
              aliColumnsEffective.map((c) => {
                let column = null;
                switch (c.dataKey) {
                  case 'category': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'listID': column = { ...c, width: 80 }; break;
                  case 'listName': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'ali': column = { ...c, width: 130 }; break;
                  case 'polygonStatus': column = { ...c, width: 110 }; break;
                  case 'storeName': column = { ...c, width: 220 }; break;
                  case 'address': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'city': column = { ...c, width: 110, flexGrow: 0.4 }; break;
                  case 'state': column = { ...c, width: 80 }; break;
                  case 'zip': column = { ...c, width: 80 }; break;
                  case 'geoAccuracy': column = { ...c, width: 115 }; break;
                  case 'squareFootage': column = { ...c, width: 90 }; break;
                  case 'sc': column = { ...c, width: 70 }; break;
                  case 'users': column = { ...c, width: 130, flexGrow: 1 }; break;
                  case 'statusSimple': column = { ...c, width: 70 }; break;
                  case 'dateEffective': column = { ...c, width: 100 }; break;
                  default: column = { ...c }; break;
                }
                return column;
              })
            }
            onRowClick={(e) => { this.onLocationSelected(e.rowData); }}
            getRowClassName={(row) => this.getALITableRowClass(row)}
            getTableDataCallback={currentTab === C.TABS.TAB_THASOS && getTableDataCallbackExt}
          />
        </div>
        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_OUTLIER && classes.activeTab)}>
          <TableView
            data={filterLocations(outlierList)}
            columns={
              aliColumnsEffective.map((c) => {
                let column = null;
                switch (c.dataKey) {
                  case 'category': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'listID': column = { ...c, width: 80 }; break;
                  case 'listName': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'ali': column = { ...c, width: 130 }; break;
                  case 'polygonStatus': column = { ...c, width: 110 }; break;
                  case 'storeName': column = { ...c, width: 220 }; break;
                  case 'address': column = { ...c, width: 140, flexGrow: 1 }; break;
                  case 'city': column = { ...c, width: 110, flexGrow: 0.4 }; break;
                  case 'state': column = { ...c, width: 80 }; break;
                  case 'zip': column = { ...c, width: 80 }; break;
                  case 'geoAccuracy': column = { ...c, width: 115 }; break;
                  case 'squareFootage': column = { ...c, width: 90 }; break;
                  case 'sc': column = { ...c, width: 70 }; break;
                  case 'users': column = { ...c, width: 130, flexGrow: 1 }; break;
                  case 'statusSimple': column = { ...c, width: 70 }; break;
                  case 'dateEffective': column = { ...c, width: 100 }; break;
                  default: column = { ...c }; break;
                }
                return column;
              })
            }
            onRowClick={(e) => { this.onLocationSelected(e.rowData); }}
            getRowClassName={(row) => this.getALITableRowClass(row)}
            getTableDataCallback={currentTab === C.TABS.TAB_OUTLIER && getTableDataCallbackExt}
          />
        </div>

        <div className={clsx(classes.tab, currentTab === C.TABS.TAB_UPLOAD_ALIS && classes.activeTab)}>
          <div className={classes.root}>
            <div className={classes.csvContainer}>
              <span className={classes.csvUploadLabel}> Please click on the upload csv button to perform an ALI search</span>
              <label htmlFor="contained-csv-file">
                <input
                  accept="csv/*"
                  className={classes.displayNone}
                  id="contained-csv-file"
                  multiple
                  type="file"
                  onChange={(e) => this.handleChangeCsv(e)}
                />
                <Button variant="contained" color="primary" component="span">
                  Upload csv
                  <AttachFileIcon />
                </Button>
              </label>
            </div>
            <div className={classes.warningContainer}>
              {
                (alisNotFound && alisNotFound.length > 0) && (
                  <div className={classes.warning}>
                    <strong>Unable to find the following ALIs!</strong>
                    {'\n'}
                    <strong>{alisNotFound.join(', ')}</strong>
                  </div>
                )
              }
            </div>
            {
              alisFound && alisFound.length > 0 && (
                <TableView
                  data={filterLocations(alisFound)}
                  columns={
                    aliColumnsEffective.map((c) => {
                      let column = null;
                      switch (c.dataKey) {
                        case 'category': column = { ...c, width: 140, flexGrow: 1 }; break;
                        case 'listID': column = { ...c, width: 80 }; break;
                        case 'listName': column = { ...c, width: 140, flexGrow: 1 }; break;
                        case 'ali': column = { ...c, width: 130 }; break;
                        case 'polygonStatus': column = { ...c, width: 110 }; break;
                        case 'storeName': column = { ...c, width: 220 }; break;
                        case 'address': column = { ...c, width: 140, flexGrow: 1 }; break;
                        case 'city': column = { ...c, width: 110, flexGrow: 0.4 }; break;
                        case 'state': column = { ...c, width: 80 }; break;
                        case 'zip': column = { ...c, width: 80 }; break;
                        case 'geoAccuracy': column = { ...c, width: 115 }; break;
                        case 'squareFootage': column = { ...c, width: 90 }; break;
                        case 'sc': column = { ...c, width: 70 }; break;
                        case 'users': column = { ...c, width: 130, flexGrow: 1 }; break;
                        case 'statusSimple': column = { ...c, width: 70 }; break;
                        case 'dateEffective': column = { ...c, width: 100 }; break;
                        default: column = { ...c }; break;
                      }
                      return column;
                    })
                  }
                  onRowClick={(e) => { this.onLocationSelected(e.rowData); }}
                  onStickyRowClick={(row) => { onExitALIAreaSearch(row); }}
                  getRowClassName={(row) => this.getALITableRowClass(row)}
                  getTableDataCallback={currentTab === C.TABS.TAB_UPLOAD_ALIS && getTableDataCallbackExt}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

LocationSearch.defaultProps = {
  searchResultList: undefined,
  onLocationSelected: () => {},
  onListSelected: () => {},
  onMapSearchMode: () => {},
  onNoPolygonsTab: () => {},
  onThasosTab: () => {},
  onOutlierTab: () => {},
  onExitALIAreaSearch: () => {},
  onCsvUpload: () => {},
  getTableDataCallback: null,
  linkALIMode: false,
  aliAreaList: null,
  aliAreaSearch: null,
  goldenSet: false,
  showSource: C.SHOW_SOURCE_ALL,
  alisFound: [],
  alisNotFound: [],
  tab: 0,
};

LocationSearch.propTypes = {
  tab: PropTypes.number,
  lists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchResultList: PropTypes.arrayOf(PropTypes.shape()),
  currentAreaList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  noPlygonsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  thasosList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  outlierList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  aliAreaList: PropTypes.arrayOf(PropTypes.shape()),
  aliAreaSearch: PropTypes.shape(),
  alisFound: PropTypes.arrayOf(PropTypes.shape()),
  alisNotFound: PropTypes.arrayOf(PropTypes.string),
  onLocationSelected: PropTypes.func,
  onListSelected: PropTypes.func,
  onMapSearchMode: PropTypes.func,
  onNoPolygonsTab: PropTypes.func,
  onThasosTab: PropTypes.func,
  onOutlierTab: PropTypes.func,
  onExitALIAreaSearch: PropTypes.func,
  onCsvUpload: PropTypes.func,
  getTableDataCallback: PropTypes.func,
  linkALIMode: PropTypes.bool,
  goldenSet: PropTypes.bool,
  showSource: PropTypes.string,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(LocationSearch);
