import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
  handleClose(index) {
    const { dialog } = this.props;
    dialog.handler(index);
  }

  render() {
    const { dialog } = this.props;
    return (
      <Dialog
        open={dialog.open}
        onClose={() => this.handleClose(-1)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
                    dialog.buttons.map((b, i) => (
                      <Button onClick={() => this.handleClose(i)} color="primary" key={b}>
                        {b}
                      </Button>
                    ))
                }
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.defaultProps = {
  dialog: {
    handler: () => {},
    text: '',
    title: '',
    buttons: [],
  },
};

AlertDialog.propTypes = {
  dialog: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    handler: PropTypes.func,
    text: PropTypes.string,
    title: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default AlertDialog;
