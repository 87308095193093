import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStylesPropTypes } from '../helper/misc';
import AuthAPI from '../service/auth';

const useStyles = ((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: '',
        password: '',
      },
      validator: {
        email: false,
        password: false,
        form: false,
      },
      errors: [],
    };
  }

  componentDidMount() {
    AuthAPI.logOut();
  }

  handleSubmit(e) {
    e.preventDefault();
    const me = this;
    const { validator, fields } = this.state;
    this.setState({ errors: [] });
    if (validator.form) {
      AuthAPI.login(fields).then(() => {
        me.props.history.push('/');
      }).catch((err) => {
        const messages = [];
        if (err && err.message) {
          messages.push(err.message);
        } else {
          messages.push('Internal error. Please try again later.');
        }
        this.setState({ errors: messages });
      });
    }
    return false;
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    const { fields } = this.state;
    fields[name] = value;

    this.setState({ fields }, () => { this.validateField(name, value); });
  }

  validateField(name, value) {
    const { validator } = this.state;
    switch (name) {
      case 'password': validator.password = (value && value.length); break;
      case 'email': validator.email = (value && value.length); break;
      default: break;
    }
    validator.form = validator.email && validator.password;
    this.setState({ validator });
  }

  render() {
    const { classes } = this.props;
    const { fields, validator, errors } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">Sign In</Typography>
          {
                        errors.map((message) => (
                          <Typography variant="subtitle1" color="error" key={message}>{message}</Typography>
                        ))
                    }
          <form className={classes.form} noValidate onSubmit={(event) => this.handleSubmit(event)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={fields.email}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={fields.password}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!validator.form}
            >
              Sign In
            </Button>
            {/* <Grid container justify="flex-end">
                            <Grid item>
                            <Link href="/register" variant="body2">
                                Don't have an account? Sign Up
                            </Link>
                            </Grid>
                        </Grid> */}
          </form>
        </div>
      </Container>
    );
  }
}

Login.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(Login);
