import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import TableView from './tableview';
import UserDialog from './userdialog';
import AdminAPI from '../service/adminapi';
import { withStylesPropTypes } from '../helper/misc';

const useStyles = (() => ({
  toolbar: {
    height: '48px',
    flexGrow: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

const userColumns = [
  { label: 'Email', dataKey: 'email' },
  { label: 'Name', dataKey: 'name' },
  { label: 'Role', dataKey: 'role' },
];

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      currentUser: null,
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  onNewUser() {
    this.setState({
      currentUser: {},
    });
  }

  onUserSelected(user) {
    this.setState({
      currentUser: user,
    });
  }

  onUserEdited() {
    this.loadUsers();
  }

  loadUsers() {
    const me = this;
    AdminAPI.getUsers().then((users) => {
      me.setState({
        users,
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { users, currentUser } = this.state;

    return (
      <>
        <div className={classes.toolbar}>
          <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={() => this.onNewUser()}>New User</Button>
        </div>
        <div className={classes.content}>
          <TableView
            data={users}
            columns={
                userColumns.map((c) => {
                  let column = null;
                  switch (c.dataKey) {
                    case 'name': column = { ...c, width: 200, flexGrow: 1 }; break;
                    case 'email': column = { ...c, width: 200, flexGrow: 1 }; break;
                    case 'role': column = { ...c, width: 200, flexGrow: 1 }; break;
                    default: column = { ...c }; break;
                  }
                  return column;
                })
              }
            onRowClick={(e) => { this.onUserSelected(e.rowData); }}
          />
        </div>

        <UserDialog
          onOK={(user) => this.onUserEdited(user)}
          onClose={() => this.setState({ currentUser: null })}
          user={currentUser}
        />
      </>
    );
  }
}

AdminUsers.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(AdminUsers);
