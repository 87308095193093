/* eslint-disable no-param-reassign */
import User from '../service/user';

const handlePolygonOwnerOnEdit = (poly) => {
  if (User.canApprove()) {
    poly.properties = {
      ...poly.properties,
      userRole: User.getRole(),
      status: User.getNewGeofenceStatus(),
      userId: User.getId(),
    };
  }
};

export { handlePolygonOwnerOnEdit };

export default { };
