import axios from 'axios';
import Config from '../config';
import { error } from '../helper/misc';

class AdminAPI {
  constructor() {
    this.axios = axios;
  }

  getUsers() {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}users`)
        .then((result) => resolve(result.data))
        .catch((err) => reject(error(err)));
    });
  }

  getExcelReport(dateFrom,dateTo){
    const me =this;
    const params=[`fromDate=${dateFrom}`, `toDate=${dateTo}`];
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}users/excelReport?${params.join('&')}`)
        .then((result) => {
          resolve(result.data)})
        .catch((err) => reject(error(err)));
    });
  }

  updateUser(user) {
    const me = this;
    const data = { ...user };
    if (user.id) {
      if (!data.password || !data.password.length) delete data.password;
      return new Promise((resolve, reject) => {
        me.axios.post(`${Config.api_endpoint}users/update`, data)
          .then((result) => resolve(result.data))
          .catch((err) => reject(error(err)));
      });
    }
    return new Promise((resolve, reject) => {
      me.axios.post(`${Config.api_endpoint}users/`, data)
        .then((result) => resolve(result.data))
        .catch((err) => reject(error(err)));
    });
  }

  getUsersReport(dateFrom, dateTo) {
    const me = this;

    const params = [`fromDate=${dateFrom}`, `toDate=${dateTo}`];
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}users/data?${params.join('&')}`)
        .then((result) => resolve(AdminAPI.convertUsersReportFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getUserReport(dateFrom, dateTo, userId) {
    const me = this;

    const params = [`fromDate=${dateFrom}`, `toDate=${dateTo}`, `userId=${userId}`];
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}users/data?${params.join('&')}`)
        .then((result) => resolve(AdminAPI.convertUserReportFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }



  static convertUserStatFromAPI(d) {
    return {
      total: (d.PARKING || 0) + (d.NONPARKING || 0),
      parkingLots: d.PARKING || 0,
      nonParkingLots: d.NONPARKING || 0,
      pending: d.PENDING || 0,
      accepted: d.ACCEPTED || 0,
      failed: d.FAILED || 0,
    };
  }

  static convertUsersReportFromAPI(data) {
    return data.map((d) => ({
      ...AdminAPI.convertUserStatFromAPI(d),
      id: d.user._id,
      user: d.user.email,
      role: d.user.role,
    }));
  }

  static convertUserReportFromAPI(data) {
    const ret = [];
    if (data.length) {
      Object.keys(data[0].lists).forEach((listName) => {
        const stat = AdminAPI.convertUserStatFromAPI(data[0].lists[listName]);
        stat.listName = listName;
        ret.push(stat);
      });
    }
    return ret;
  }

  uploadPolygons(data, polygonType) {
    const me =this;

    var formData = new FormData();
    formData.append("csv", data);
    const copy = polygonType === 'copy'
    // formData.append("polygonType", polygonType);

    return new Promise((resolve, reject) => {
      me.axios.post(`${Config.api_endpoint}admin/polygons?copy=${copy}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((result) => resolve(result.data))
      .catch((err) => reject(error(err)));
    });
  }
}

export default new AdminAPI();
