 import React, {Component} from 'react'; 
  
class UploadFile extends Component {

    constructor(props) {
        super(props);
      }
   
    state = {
      selectedFile: null
    };
     
    onFileChange = event => {
      this.setState({ selectedFile: event.target.files[0] });
    };
    render() {
     
      return (
        <div>
            <div>
                <input type="file" onChange={this.onFileChange} />
            </div>
        </div>
      );
    }
  }
  
  export default UploadFile;