import React from 'react';
import PropTypes from 'prop-types';
import KeyCode from 'key-code';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../helper/misc';

const useStyles = (() => ({
  input: {
    backgroundColor: '#ffffff',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, 2px) scale(0.75)',
    },
  },
}));

class ALISearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      ali: '',
    };
  }

  onSearch(ali) {
    const { onSearch } = this.props;
    onSearch(ali);
  }

  handleKeyDown(event) {
    const { keyCode, target } = event;
    switch (keyCode) {
      case KeyCode.ENTER:
        target.blur();
        break;

      case KeyCode.ESC:
        this.setState({ ali: '' });
        this.onSearch('');
        break;

      default:
        break;
    }
  }

  handleBlur() {
    const { ali } = this.state;
    this.onSearch(ali);
  }

  render() {
    const { classes } = this.props;
    const { ali } = this.state;

    return (
      <TextField
        variant="outlined"
        fullWidth
        name="ali"
        label="Search ALI"
        type="text"
        id="ali"
        className={classes.input}
        value={ali}
        onChange={(event) => this.setState({ ali: event.target.value })}
        onKeyDown={(event) => this.handleKeyDown(event)}
        onBlur={(event) => this.handleBlur(event)}
      />
    );
  }
}

ALISearchBar.defaultProps = {
  onSearch: () => {},
};

ALISearchBar.propTypes = {
  onSearch: PropTypes.func,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(ALISearchBar);
