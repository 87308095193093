import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LinkIcon from '@material-ui/icons/Link';
import BuildIcon from '@material-ui/icons/Build';
import DoneIcon from '@material-ui/icons/Done';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point, polygon } from '@turf/helpers';
import { intersect, pointOnFeature } from '@turf/turf';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Select } from '@material-ui/core';
import ColorProvider from '../helper/colorprovider';
import ApproveBar from './approvebar';
import User from '../service/user';
import ALIAPI from '../service/aliapi';
import {
  withStylesPropTypes, formatAddress, downloadTextFile, hashCode, formatDate,
} from '../helper/misc';
import { handlePolygonOwnerOnEdit } from '../helper/polygon-helper';
import * as C from '../constants';

window.google = window.google ? window.google : {};
const { google } = window;

const DEFAULT_RADIUS_SEARCH = 0.1;
const MAX_RADIUS_SEARCH = 20;
const POLYGON_OFFSET = 0.0001;

const useStyles = ((theme) => ({
  small: {
    fontSize: '0.6em',
  },
  polygonName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '30px',
  },
  polygonButtons: {
    float: 'right',
    position: 'relative',
    top: '-10px',
  },
  linkButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  swithLink: {
    cursor: 'pointer',
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.dark,
    },
    '&:focus': {
      outlined: 'none',
    },
  },
  vertexLine: {
    display: 'flex',
  },
  vertex: {
    fontSize: '0.8em',
    textAlign: 'left',
    justifyContent: 'start',
    flexGrow: 1,
  },
  selectedVertex: {
    border: 'solid 1px #00ff00',
  },
  duplicateVertex: {
    color: '#990000',
    fontWeight: 'bold',
  },
  createALI: {
    padding: '5px',
    margin: '5px',
    border: 'solid 1px #666666',
  },
  createALIButtons: {
    paddingTop: '5px',
    display: 'flex',
    '&>button': {
      flexGrow: 1,
      flexBasis: 0,
      margin: '2px',
    },
  },
  constructionFlag: {
    margin: '10px 0',
    width: '100%',
  },
}));

class LocationProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aliAreaSearch: undefined,
      currentPolygonIndex: undefined,
      scrollToPolyonIndex: undefined,
      currentVertexIndex: undefined,
      expandedCoordinates: {},
      createAliAttributes: undefined,
    };
    this.color_provider = new ColorProvider();
  }

  componentDidUpdate(prevProps) {
    const { ali, selectedPolygon } = this.props;
    if (prevProps.ali !== ali) {
      this.onALIRecevied(ali);
    }

    if (prevProps.selectedPolygon !== selectedPolygon) {
      this.onSelectedPolygonChange(selectedPolygon);
    }
  }

  onALIRecevied() {
    this.color_provider.reset();
    this.setState({
      aliAreaSearch: undefined,
      createAliAttributes: undefined,
    });
    this.setState({
      expandedCoordinates: {},
    });
  }

  onSelectedPolygonChange(selectedPolygon) {
    this.setState({
      currentPolygonIndex: (selectedPolygon || {}).polygonIndex,
      scrollToPolyonIndex: (selectedPolygon || {}).polygonIndex,
      currentVertexIndex: (selectedPolygon || {}).vertexIndex,
    });
  }

  onRenamePolygon(index) {
    const { ali } = this.props;
    ali.polygons[index].renaming = true;
    this.onALIChanged(ali);
  }

  onDeletePolygon(index) {
    const { onAlert } = this.props;
    onAlert({
      title: 'Warning',
      text: 'Are you sure you want to delete polygon?',
      buttons: ['Yes', 'No'],
      handler: (button) => {
        if (button === 0) {
          const { ali } = this.props;
          if (ali.polygons[index].id) {
            ali.polygons[index].deleted = true;
          } else {
            ali.polygons.splice(index, 1);
          }
          ali.modified = true;
          this.onALIChanged({ ...ali });
        }
      },
    });
  }

  onHidePolygon(index) {
    const { ali } = this.props;
    ali.polygons[index].hidden = true;
    this.onALIChanged({ ...ali });
  }

  onShowPolygon(index) {
    const { ali } = this.props;
    ali.polygons[index].hidden = false;
    this.onALIChanged({ ...ali });
  }

  onDuplicatePolygon(index) {
    const { ali } = this.props;
    const selectedPolygon = ali.polygons[index];
    const coordinates = selectedPolygon.coordinates.map((c) => [c[0] + POLYGON_OFFSET, c[1] + POLYGON_OFFSET]);
    const now = new Date().toISOString();
    const dupPolygon = {
      properties: selectedPolygon.properties,
      name: `${selectedPolygon.name} copy` || 'Polygon copy',
      coordinates,
      createdAt: now,
      updatedAt: now,
    };
    ali.polygons.push(dupPolygon);
    this.onALIChanged({ ...ali });
  }

  onALIChanged(ali) {
    const { onALIChanged } = this.props;
    onALIChanged(ali);
  }

  onSave() {
    const { onSaveALI } = this.props;
    onSaveALI();
  }

  onClose() {
    const { onCloseALI } = this.props;
    onCloseALI();
  }

  onDownloadGeoJSON() {
    const { ali } = this.props;
    const geoJson = {
      type: 'FeatureCollection',
      features: ali.polygons.filter((p) => !p.deleted).map((p) => {
        const feature = {
          geometry: {
            coordinates: [p.coordinates],
            type: 'Polygon',
          },
          properties: { ...p.properties, name: p.name },
          type: 'Feature',
        };
        return feature;
      }),
    };

    downloadTextFile('polygons.geojson', JSON.stringify(geoJson));
  }

  onWipeOff() {
    const { onAlert } = this.props;
    onAlert({
      title: 'Warning',
      text: 'This will remove all polygons and geocode the location. Are you sure you want to continue?',
      buttons: ['Yes', 'No'],
      handler: (button) => {
        if (button === 0) {
          const { ali } = this.props;
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: formatAddress(ali) }, (results, status) => {
            if (status === 'OK' && results && results.length) {
              const [result = {}] = results;
              const { geometry = {} } = result;
              const { location } = geometry;
              if (location) {
                if (!Number.isFinite(ali.original_latitude) || !Number.isFinite(ali.original_longitude)) {
                  ali.original_latitude = ali.latitude;
                  ali.original_longitude = ali.longitude;
                }
                ali.latitude = location.lat();
                ali.longitude = location.lng();
                ali.position_modified = true;
              }
            }
            ali.polygons = ali.polygons.filter((p) => {
              if (p.id) {
                // eslint-disable-next-line no-param-reassign
                p.deleted = true;
                return true;
              }
              return false;
            });
            ali.modified = true;
            this.onALIChanged({ ...ali });
          });
        }
      },
    });
  }

  onSearchRadius() {
    const { ali } = this.props;
    this.setState({
      aliAreaSearch: {
        ali: { ...ali },
        radius: DEFAULT_RADIUS_SEARCH,
      },
    });
  }

  onShowTenants() {
    const { ali } = this.props;
    const aliAreaSearch = {
      ali: { ...ali },
      showTenants: true,
    };
    this.setState({
      aliAreaSearch,
    });

    const { onALIAreaSearch } = this.props;
    onALIAreaSearch(aliAreaSearch);
  }

  onLinkALI() {
    const { onLinkALI } = this.props;
    onLinkALI();
  }

  onCreateALI() {
    const { ali } = this.props;

    const polygons = ali.polygons.filter((p) => !p.deleted).map((p) => polygon([p.coordinates]));
    if (polygons.length) {
      let finalPolygon = polygons[0];
      for (let i = 1; i < polygons.length; i += 1) {
        const intersection = intersect(finalPolygon, polygons[i]);
        finalPolygon = intersection || finalPolygon;
      }

      const pt = pointOnFeature(finalPolygon);
      const [lng, lat] = pt.geometry.coordinates;

      let createAliAttributes = {
        latitude: lat,
        longitude: lng,
      };
      this.setState({ createAliAttributes });

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK' && results && results.length) {
          const [result] = results;
          const { address_components: addressComponents } = result;
          const findAddressComponentName = (components, type, shortName) => {
            const comp = components.find((c) => c.types.includes(type));
            if (comp) return shortName ? comp.short_name : comp.long_name;
            return undefined;
          };

          const address = [
            findAddressComponentName(addressComponents, 'street_number'),
            findAddressComponentName(addressComponents, 'route'),
          ].filter((c) => !!c).join(' ');

          const city = findAddressComponentName(addressComponents, 'locality');
          const state = findAddressComponentName(addressComponents, 'administrative_area_level_1', true);
          const county = findAddressComponentName(addressComponents, 'administrative_area_level_2', true);
          const zipCode = [
            findAddressComponentName(addressComponents, 'postal_code'),
            findAddressComponentName(addressComponents, 'postal_code_suffix'),
          ].filter((c) => !!c).join('-');

          createAliAttributes = {
            ...createAliAttributes,
            address,
            city,
            county,
            state,
            zipCode
          };
        }
        this.setState({ createAliAttributes });
      });
    }
  }

  // eslint-disable-next-line react/sort-comp
  canEdit() {
    const { ali } = this.props;
    return !(ali && ali.readOnly);
  }

  handleCreateAliAttributesChange(e) {
    const { name, value } = e.target;
    const { createAliAttributes } = this.state;
    this.setState({
      createAliAttributes: {
        ...createAliAttributes,
        [name]: value,
      },
    });
  }

  handleCreateALI() {
    const { createAliAttributes } = this.state;
    const errors = {};
    ['listId', 'storeName', 'address', 'city', 'state', 'zipCode', 'latitude', 'longitude'].forEach((field) => {
      if (!createAliAttributes[field]) errors[field] = true;
    });
    if (Object.keys(errors).length) {
      this.setState({
        createAliAttributes: {
          ...createAliAttributes,
          errors,
        },
      });
    } else {
      const params = { ...createAliAttributes };
      this.setState({
        createAliAttributes: undefined,
      });
      delete params.errors;

      const { ali } = this.props;
      ALIAPI.createALI(params).then((data) => {
        const newAli = {
          ...data,
          polygons: ali.polygons,
          polygonStatus: ali.polygonStatus,
          polygonsToDisplay: ali.polygonsToDisplay,
          property: ali.property || {},
          modified: true,
          ali_linked: true,
          geoAccuracy: 'AGGDATA_VERIFIED: ROOFTOP',
        };
        this.onALIChanged(newAli);
      });
    }
  }

  handleSwitchToAli(ali) {
    const { onSwitchToALI } = this.props;
    onSwitchToALI(ali);
  }

  handlePropertyChange(event, isSwitch = false) {
    const { value, name, checked } = event.target;
    const { ali } = this.props;
    if (!ali.property) ali.property = {};
    ali.property[name] = isSwitch ? checked : value;
    if (name === 'requestFlag' && checked) {
      if (!ali.property.requestNames) ali.property.requestNames = [];
      ali.property.requestNames = ali.property.requestNames.filter((n) => !!n);
      const namesCount = ali.property.requestNames.length;
      if (!namesCount || ali.property.requestNames[namesCount - 1]) ali.property.requestNames.push('');
    }
    ali.modified = true;
    this.onALIChanged(ali);
  }


  handleManualSqftChange(e) {
    const { value } = e.target;
    if (!(/^\d+$/.test(value)) && value !== '') { return; }
    const { ali } = this.props;
    ali.manualSqft = value || "";
    ali.manualSqftModified = true
    ali.modified = true;
    this.onALIChanged(ali);
  }
  
  handleHideFlagChange(event, isSwitch = false) {
    const { value } = event.target;
    const { ali } = this.props;
    if (value === 'hideFromApp') {
      ali.hideFromApp = true;
      ali.hideTraffic = false;
    } else if (value === 'hideTraffic') {
      ali.hideFromApp = false;
      ali.hideTraffic = true;
    } else {
      ali.hideFromApp = false;
      ali.hideTraffic = false;
    }
    ali.modified = true;
    this.onALIChanged(ali);
  }

  handlePropertyRequestNameChange(index, event) {
    const { value } = event.target;
    const { ali } = this.props;
    if (!ali.property) ali.property = {};
    let names = [...ali.property.requestNames];
    names[index] = value;
    names = names.filter((n) => !!n);
    names.push('');
    ali.property.requestNames = names;
    ali.modified = true;
    this.onALIChanged(ali);
  }

  handlePolygonPropertyChange(event, index, isSwitch = false) {
    const { name, value, checked } = event.target;
    const { ali } = this.props;
    ali.polygons[index].properties[name] = isSwitch ? checked : value;
    ali.modified = true;
    this.onALIChanged(ali);
  }

  handleRenamePolygon(event, index) {
    const { value } = event.target;
    const { ali } = this.props;
    ali.modified = true;
    ali.polygons[index].name = value;
    this.onALIChanged(ali);
  }

  handleRenamePolygonEnd(event, index) {
    const { type, which, target } = event;
    if (type === 'keydown') {
      if (which === 13) {
        target.blur();
      }
    } else {
      const { ali } = this.props;
      delete ali.polygons[index].renaming;
      this.onALIChanged({ ...ali });
    }
  }

  handleApproveStatus({ indexes, status, statusMessage }) {
    const { ali } = this.props;

    indexes.forEach((i) => {
      ali.polygons[i].properties.status = status || C.STATUS_VERIFIED_MISMATCH;
      ali.polygons[i].properties.statusMessage = statusMessage;
    });

    ali.modified = true;
    this.onALIChanged(ali);
  }

  handleRadiusSearchChange(event) {
    const { value } = event.target;
    const { aliAreaSearch } = this.state;
    if (aliAreaSearch) {
      this.setState({
        aliAreaSearch: {
          ...aliAreaSearch,
          radius: value,
        },
      });
    }
  }

  handleAliAreaSearchCancel() {
    this.setState({
      aliAreaSearch: undefined,
    });
    const { onALIAreaSearch } = this.props;
    onALIAreaSearch();
  }

  handleRadiusSearchEnd(event) {
    const { type, which, target } = event;
    if (type === 'keydown') {
      if (which === 13) {
        target.blur();
      } else if (which === 27) {
        this.handleAliAreaSearchCancel();
      }
    } else {
      const { aliAreaSearch } = this.state;
      let radius = Number.parseFloat(aliAreaSearch.radius);
      if (Number.isNaN(radius)) {
        radius = DEFAULT_RADIUS_SEARCH;
      } else if (radius < 0) {
        radius = DEFAULT_RADIUS_SEARCH;
      } else if (radius > MAX_RADIUS_SEARCH) {
        radius = MAX_RADIUS_SEARCH;
      }

      const newAliAreaSearch = {
        ...aliAreaSearch,
        radius,
      };

      this.setState({
        aliAreaSearch: newAliAreaSearch,
      });

      const { onALIAreaSearch } = this.props;
      onALIAreaSearch(newAliAreaSearch);
    }
  }

  handleVertexSelect(polygonIndex, vertexIndex) {
    const { onPolygonSelected } = this.props;
    onPolygonSelected({
      polygonIndex,
      vertexIndex,
    });
  }

  handleVertexDelete(polygonIndex, vertexIndex) {
    const { ali } = this.props;
    const poly = ali.polygons[polygonIndex];
    const coords = poly.coordinates;
    coords.splice(vertexIndex, 1);
    if (vertexIndex === 0) {
      const [firstCoord] = coords;
      coords[coords.length - 1] = firstCoord;
    }
    ali.modified = true;
    handlePolygonOwnerOnEdit(poly);
    this.onALIChanged({ ...ali });
  }

  handleShowCoordinated(index, show) {
    const { expandedCoordinates } = this.state;
    const updatedExpandedCoordinates = { ...expandedCoordinates };
    if (show) updatedExpandedCoordinates[index] = true;
    else delete updatedExpandedCoordinates[index];
    this.setState({
      expandedCoordinates: updatedExpandedCoordinates,
    });
  }

  handleOverlapRisksClick() {
    const { onShowOverlapRisks, nearByAlis } = this.props;
    onShowOverlapRisks(nearByAlis);
  }

  render() {
    const {
      classes, ali, editablePolygons, createALILists, nearByAlis,
    } = this.props;
    const {
      aliAreaSearch, currentPolygonIndex, scrollToPolyonIndex, currentVertexIndex, expandedCoordinates, createAliAttributes,
    } = this.state;

    const statusMessages = [];
    if (User.canSeeStatusMessage() && ali.polygonsToDisplay) {
      ali.polygonsToDisplay.forEach((p) => {
        if (p.properties.statusMessage && statusMessages.indexOf(p.properties.statusMessage) < 0) {
          statusMessages.push(p.properties.statusMessage);
        }
      });
    }

    const aliProperties = [];
    if (ali.ali) {
      aliProperties.push({ name: 'ALI', value: ali.ali });
      aliProperties.push({ name: 'List ID', value: ali.listID });
      aliProperties.push({ name: 'List Name', value: ali.listName });
     
      if (ali.statusSimple) aliProperties.push({ name: 'Status', value: ali.statusSimple });
      if (ali.dateEffective) aliProperties.push({ name: 'Date Effective', value: ali.dateEffective });
      if (ali.landlord) aliProperties.push({ name: 'Type', value: 'Landlord' });
      else if (ali.parent && ali.parent.ali) {
        aliProperties.push({ name: 'Type', value: 'Tenant' });
        aliProperties.push({ name: 'Parent ALI', value: ali.parent.ali, switchTo: ali.parent.ali });
        if (ali.parent.storeName) aliProperties.push({ name: 'Parent Store Name', value: ali.parent.storeName });
        if (ali.parent.listName) aliProperties.push({ name: 'Parent List Name', value: ali.parent.listName });
      }
      if (ali.squareFootage) aliProperties.push({ name: 'SQFT', value: ali.squareFootage });
      aliProperties.push({ name: 'Golden Set', value: ali.goldenSet ? 'Y' : 'N' });
      aliProperties.push({ name: 'Latitude', value: ali.latitude });
      aliProperties.push({ name: 'Longitude', value: ali.longitude });
      if (Number.isFinite(ali.original_latitude) && Number.isFinite(ali.original_longitude)) {
        aliProperties.push({ name: 'Original Latitude', value: ali.original_latitude });
        aliProperties.push({ name: 'Original Longitude', value: ali.original_longitude });
      }
      aliProperties.push({ name: 'Geo Accuracy', value: ali.geoAccuracy });
      aliProperties.push({ name: 'Distributor', value: ali.distributorName });
      aliProperties.push({ name: 'Category', value: ali.category });
      aliProperties.push({ name: 'Store Name', value: ali.storeName });
      aliProperties.push({ name: 'Store Front', value: ali.storefront });
      aliProperties.push({ name: 'Phone', value: ali.phoneNumber });
      aliProperties.push({ name: 'URL', value: ali.sourceUrl });

      if (ali.coBranded && !_.isEmpty(ali.coBrandedAli)) {
        aliProperties.push({ name: 'CoBranded', value: ali.coBranded ? 'Yes' : 'No' });
        aliProperties.push({ name: 'CoBranded ALI', value: ali.coBrandedAli.join(',') });
      }
      if (ali.withInStore && !_.isEmpty(ali.withInStoreAli)) {
        aliProperties.push({ name: 'WithIn Store', value: ali.withInStore ? 'Yes' : 'No' });
        aliProperties.push({ name: 'WithIn Store ALI', value: ali.withInStoreAli.join(',') });
      }

      const sharingBorders = !_.isEmpty(ali.touchingBordersWithAli);
      if (sharingBorders) {
        aliProperties.push({ name: 'Sharing Borders', value: sharingBorders ? 'Yes' : 'No' });
        aliProperties.push({ name: 'Sharing Borders With', value: ali.touchingBordersWithAli.join(',') });
      }
       //-----------------addition of siteplan url----------------------------------
        if(ali.landlord&&ali.sitePlanUrl){
          aliProperties.push({name:'sitePlanUrl',value:ali.sitePlanUrl})
        }else if(ali.parent&&ali.parent.landlord&&ali.parent.sitePlanUrl){
          aliProperties.push({name:'sitePlanUrl',value:ali.parent.sitePlanUrl})
        }else{
          aliProperties.push({name:'sitePlanUrl',value:""})
        }
      //----------------------------------------------------------------------------
    }

    const duplicatedVertex = {};
    if (ali.polygonsToDisplay) {
      ali.polygonsToDisplay.forEach((p) => {
        const map = {};
        p.coordinates.filter((c, cidx) => cidx < p.coordinates.length - 1).forEach((c, cidx) => {
          const key = `${c[1]},${c[0]}`;
          let vertex = map[key];
          if (!vertex) {
            vertex = [];
            map[key] = vertex;
          }
          vertex.push(cidx);
        });
        const dups = {};
        Object.values(map).filter((l) => l.length > 1).forEach((l) => {
          l.forEach((i) => {
            dups[i] = true;
          });
        });
        duplicatedVertex[p.index] = dups;
      });
    }
    const hasDuplicatedVertex = Object.values(duplicatedVertex).some((indexes) => Object.keys(indexes).length > 0);

    const aliMode = !!ali.ali && ali.listID > 0;
    const propertyMode = !aliMode && ((ali.polygons && ali.polygons.length) || ali.property);

    const today = formatDate(new Date());

    let canSave = false;
    let validationError;
    if (this.canEdit()) {
      canSave = ali.modified && ali.polygons && ali.polygons.length;
      const { constructionFlag } = ali.property || {};
      if (ali.property) {
        if (ali.modified && constructionFlag && constructionFlag !== C.NO_CONSTRUCTION_FLAG) canSave = true;
        if (ali.property.requestFlag) {
          if (!ali.property.requestNames) {
            canSave = false;
            validationError = 'At least one Custom Request Name should be entered';
          } else {
            const requestNames = ali.property.requestNames.filter((n) => !!n);
            if (requestNames.length === 0) {
              validationError = 'At least one Custom Request Name should be entered';
              canSave = false;
            } else {
              canSave = !requestNames.some((n) => (/[%&]/.test(n)));
              if (!canSave) validationError = 'Custom Request Name can\'t contain special characters like % or &';
            }
          }
        }
        if (propertyMode && (!ali.property.name || !ali.property.name.length)) {
          canSave = false;
          validationError = 'Property Name can\'t be empty';
        }
      } else {
        canSave = false;
        validationError = 'Mandatory property attributes are missing';
      }
      if (canSave && hasDuplicatedVertex) {
        canSave = false;
        validationError = 'There are duplicated points in polygoons';
      }
      if (canSave && aliMode) {
        const aliPoint = point([ali.longitude, ali.latitude]);
        let aliInPolygon = false;
        for (let i = 0; i < ali.polygons.length; i += 1) {
          const polygonN = polygon([ali.polygons[i].coordinates]);
          if (booleanPointInPolygon(aliPoint, polygonN)) {
            aliInPolygon = true;
            break;
          }
        }
        if ((!constructionFlag || (constructionFlag === C.NO_CONSTRUCTION_FLAG)) && !aliInPolygon) {
          canSave = false;
          validationError = 'ALI coordinates is outside of polygons';
        }
      }
    } else {
      validationError = 'This ALI is read-only';
    }

    let properties = '';
    if (aliMode || propertyMode) {
      properties = (
        <div>
          <Tooltip title={validationError || ''}>
            <div style={{ width: '100%' }}>
              <Button color="primary" variant="contained" disabled={!canSave} startIcon={<SaveIcon />} onClick={() => this.onSave()}>Save</Button>
            </div>
          </Tooltip>
          <Typography component="h1" variant="h6" color="inherit">
            {ali.listName || (ali.property || {}).name || '<no name>'}
            {(ali.ali || ali.modified) && (<IconButton color="primary" onClick={() => this.onClose()}><CloseIcon /></IconButton>)}
          </Typography>
          {
            editablePolygons && propertyMode && (
              <TextField
                disabled={!this.canEdit()}
                required
                name="name"
                label="Property Name"
                type="text"
                id="property_name"
                fullWidth
                value={(ali.property || {}).name || ''}
                onChange={(event) => this.handlePropertyChange(event)}
              />
            )
          }
          {
            editablePolygons && (propertyMode || ali.category === C.CATEGORY_REAL_ESTATE) && (
              <TextField
                disabled={!this.canEdit()}
                fullWidth
                select
                name="tag"
                label="Property Type"
                value={(ali.property || {}).tag || ''}
                onChange={(event) => this.handlePropertyChange(event)}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {C.PROPERTY_TYPES.map((v) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            )
          }
          <FormControlLabel
            control={(
              <Switch
                disabled={!this.canEdit()}
                name="requestFlag"
                checked={(ali.property || {}).requestFlag || false}
                onChange={(event) => this.handlePropertyChange(event, true)}
                color="primary"
              />
                  )}
            label="Custom Request"
          />
          {
            (ali.property && ali.property.requestFlag) && (
              <>
                {
                (ali.property.requestNames || []).map((rn, rnidx) => (
                  <TextField
                    disabled={!this.canEdit()}
                    required={rnidx === 0}
                  // eslint-disable-next-line react/no-array-index-key
                    key={rnidx}
                    name={`requestName${rnidx}`}
                    label={rnidx === 0 ? 'Custom Request Name' : 'Additional Request Name'}
                    placeholder={`REQUEST_NAME_${today}`}
                    type="text"
                    id={`custom_request_name${rnidx}`}
                    fullWidth
                    value={rn}
                    onChange={(event) => this.handlePropertyRequestNameChange(rnidx, event)}
                  />
                ))
                }
              </>
            )
          }
          {
            aliMode && (
              <div className={classes.small}>
                <div><strong>{ formatAddress(ali) }</strong></div>
                {
                  aliProperties.map((p) => (
                    <div key={p.name}>
                      <strong>
                        { p.name }
                        :
                      </strong>
                      {' '}
                      { p.switchTo
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        ? (<a role="button" className={classes.swithLink} tabIndex={0} onKeyDown={() => {}} onClick={() => this.handleSwitchToAli(p.switchTo)}>{p.value}</a>)
                        : p.name==='sitePlanUrl'?(<a role="button" href={p.value} target="_blank" className={classes.swithLink}>{p.value}</a>):p.value}
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
      );
    } else {
      properties = (
        <Typography component="h1" variant="h6" color="inherit">
          Nothing Selected
        </Typography>
      );
    }

    return (
      <div style={{ overflowY: 'auto' }}>
        <div style={{ padding: '5px' }}>
          {properties}
        </div>
        <Divider />
        { (ali && ali.latitude && ali.longitude) && (
          <div style={{ padding: '5px' }}>
            <Button fullWidth style={{ marginBottom: '5px' }} color="primary" variant="outlined" onClick={() => this.onWipeOff()}>Wipe Off</Button>
            { aliAreaSearch === undefined
              ? (
                <>
                  <Button fullWidth color="primary" variant="outlined" onClick={() => this.onSearchRadius()}>Search Radius</Button>
                  { ali.landlord && (
                    <Button fullWidth color="primary" variant="outlined" onClick={() => this.onShowTenants()} style={{ marginTop: '5px' }}>Show Tenants</Button>
                  )}
                </>
              ) : (
                <>
                  { aliAreaSearch.radius !== undefined && (
                    <div style={{ display: 'flex' }}>
                      <TextField
                        required
                        name="radiusSearch"
                        label="Radius Search"
                        type="text"
                        id="radius_search"
                        value={aliAreaSearch.radius}
                        autoFocus
                        onChange={(event) => this.handleRadiusSearchChange(event)}
                        onBlur={(event) => this.handleRadiusSearchEnd(event)}
                        onKeyDown={(event) => this.handleRadiusSearchEnd(event)}
                      />
                      <IconButton color="secondary" onClick={() => this.handleAliAreaSearchCancel()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )}
                  { aliAreaSearch.showTenants && (
                    <Button fullWidth color="primary" variant="outlined" onClick={() => this.handleAliAreaSearchCancel()}>Hide Tenants</Button>
                  )}
                </>
              )}
            { nearByAlis.length > 0 && (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => this.handleOverlapRisksClick()}
                style={{ marginTop: '5px' }}
              >
                { ali.canDrawOverlaps ? 'Hide Overlap Risks' : 'Show Overlap Risks' }
              </Button>
            )}

            <TextField
              variant="outlined"
              fullWidth
              name="manualSqft"
              label="Manual SQFT"
              type="number"
              size="small"
              margin="dense"
              value={ali?.manualSqft || ""}
              onChange ={(e) => this.handleManualSqftChange(e)}
            />	

            <FormControl className={classes.constructionFlag}>
              <InputLabel htmlFor="constructionFlag">Construction Flag</InputLabel>
              <Select
                native
                value={(ali.property || {}).constructionFlag || 'None'}
                autoFocus
                inputProps={{
                  id: 'constructionFlag',
                  name: 'constructionFlag',
                }}
                onChange={(e) => this.handlePropertyChange(e, false)}
              >
                {
                C.POLYGON_FLAGS.map((flag) => (
                  <option key={flag.name} value={flag.value}>{flag.name}</option>
                ))
              }
              </Select>
            </FormControl>
            <FormControl className={classes.constructionFlag}>
              <InputLabel htmlFor="constructionFlag">Hide Flag</InputLabel>
              <Select
                native
                value={(ali.hideFromApp?"hideFromApp":ali.hideTraffic?"hideTraffic":"None")}
                autoFocus
                inputProps={{
                  id: 'hideFlag',
                  name: 'hideFlag',
                }}
                onChange={(e) => this.handleHideFlagChange(e, false)}
              >
                {
                C.HIDE_FLAGS.map((flag) => (
                  <option key={flag.name} value={flag.value}>{flag.name}</option>
                ))
              }
              </Select>
            </FormControl>
          </div>
        )}

        {
            (ali.polygonsToDisplay && ali.polygonsToDisplay.length
              ? (
                <div>
                  {
                    User.canLinkALI() && propertyMode && editablePolygons && this.canEdit() && !createAliAttributes && (
                      <div style={{ padding: '5px' }}>
                        <Button style={{ width: '100%' }} className={classes.linkButton} color="primary" variant="contained" startIcon={<LinkIcon />} onClick={() => this.onLinkALI()}>Link ALI</Button>
                      </div>
                    )
                  }
                  {
                    User.canCreateALI() && propertyMode && editablePolygons && this.canEdit() && createAliAttributes && (
                      <div className={classes.createALI}>
                        <FormControl variant="standard" className={classes.formControl} fullWidth required error={createAliAttributes.errors && createAliAttributes.errors.listId}>
                          <InputLabel htmlFor="create-new-ali-list">List</InputLabel>
                          <Select
                            native
                            value={createAliAttributes.listId || ''}
                            autoFocus
                            inputProps={{
                              id: 'create-new-ali-list',
                              name: 'listId',
                            }}
                            onChange={(e) => this.handleCreateAliAttributesChange(e)}
                          >
                            <option value=""> </option>
                            {
                              createALILists.sort((a, b) => a.listName.localeCompare(b.listName)).map((list) => (
                                <option key={list.listID} value={list.listID}>{list.listName}</option>
                              ))
                            }
                          </Select>
                        </FormControl>
                        <TextField required name="storeName" label="Store Name" type="text" value={createAliAttributes.storeName || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.storeName} />
                        <TextField required name="address" label="Address" type="text" value={createAliAttributes.address || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.address} />
                        <TextField required name="city" label="City" type="text" value={createAliAttributes.city || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.city} />
                        <TextField required name="county" label="County" type="text" value={createAliAttributes.county || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.county} />
                        <TextField required name="state" label="State" type="text" value={createAliAttributes.state || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.state} />
                        <TextField required name="zipCode" label="ZIP" type="text" value={createAliAttributes.zipCode || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.zipCode} />
                        <TextField required name="latitude" label="Latitude" type="text" value={createAliAttributes.latitude || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.latitude} />
                        <TextField required name="longitude" label="Longitude" type="text" value={createAliAttributes.longitude || ''} onChange={(e) => this.handleCreateAliAttributesChange(e)} error={createAliAttributes.errors && createAliAttributes.errors.longitude} />
                        <div className={classes.createALIButtons}>
                          <Button color="primary" variant="contained" startIcon={<DoneIcon />} onClick={() => this.handleCreateALI()}>OK</Button>
                          <Button color="secondary" variant="contained" startIcon={<CloseIcon />} onClick={() => this.setState({ createAliAttributes: undefined })}>Cancel</Button>
                        </div>
                      </div>
                    )
                  }
                  {
                    User.canCreateALI() && propertyMode && editablePolygons && this.canEdit() && !createAliAttributes && (
                      <div style={{ padding: '5px' }}>
                        <Button style={{ width: '100%' }} className={classes.linkButton} color="primary" variant="contained" startIcon={<BuildIcon />} onClick={() => this.onCreateALI()}>Create ALI</Button>
                      </div>
                    )
                  }
                  {
                    User.canEditCoordinates() && (
                      <div style={{ padding: '5px' }}>
                        <Button style={{ width: '100%' }} color="primary" variant="contained" startIcon={<CloudDownloadIcon />} onClick={() => this.onDownloadGeoJSON()}>Download GeoJSON</Button>
                      </div>
                    )
                  }
                  {
                    statusMessages.map((m) => (
                      <Alert severity="error" key={m}>{m}</Alert>
                    ))
                  }
                  <List>
                    {
                      ali.polygonsToDisplay.map((p) => (
                        <ListItem
                          key={(p.id || hashCode(p.coordinates))}
                          style={{
                            borderLeftWidth: '4px', borderLeftStyle: 'solid', borderLeftColor: this.color_provider.get(p.colorIndex), backgroundColor: this.color_provider.get(p.colorIndex, p.index === currentPolygonIndex ? 0.3 : 0.1), transition: 'background-color 0.2s linear',
                          }}
                          ref={(ref) => {
                            if (p.index === scrollToPolyonIndex && ref) {
                              ref.scrollIntoView();
                              this.setState({ scrollToPolyonIndex: undefined });
                            }
                          }}
                        >
                          <ListItemText>
                            {
                              editablePolygons && aliMode && p.siblings && this.canEdit()
                                ? (
                                  <ApproveBar polygons={p.siblings} canApprove={p.canApprove} onChange={(e) => this.handleApproveStatus(e)} />
                                )
                                : (
                                  <div />
                                )
                            }
                            {
                              editablePolygons
                              && (p.renaming
                                ? (
                                  <TextField
                                    required
                                    name="polygon_name"
                                    label="Polygon Name"
                                    type="text"
                                    id="polygon_name"
                                    value={p.name}
                                    autoFocus
                                    onChange={(event) => this.handleRenamePolygon(event, p.index)}
                                    onBlur={(event) => this.handleRenamePolygonEnd(event, p.index)}
                                    onKeyDown={(event) => this.handleRenamePolygonEnd(event, p.index)}
                                  />
                                )
                                : (
                                  <div>
                                    { this.canEdit() && (
                                      <div className={classes.polygonButtons}>
                                        <IconButton color="primary" onClick={() => this.onRenamePolygon(p.index)}><CreateIcon /></IconButton>
                                        <IconButton color="secondary" onClick={() => this.onDeletePolygon(p.index)}><DeleteIcon /></IconButton>
                                        { p.hidden
                                          ? <IconButton onClick={() => this.onShowPolygon(p.index)}><VisibilityIcon /></IconButton>
                                          : <IconButton onClick={() => this.onHidePolygon(p.index)}><VisibilityOffIcon /></IconButton>}
                                        {
                                          (p.index === currentPolygonIndex) && (
                                            <IconButton color="primary" onClick={() => this.onDuplicatePolygon(p.index)}><AddToPhotosIcon /></IconButton>
                                          )
                                        }

                                      </div>
                                    )}
                                    <Tooltip title={p.name}><div className={classes.polygonName}>{p.name}</div></Tooltip>
                                  </div>
                                )
                              )
                            }
                            {
                              User.canSeeOtherUsers() && p.lastEdit && p.lastEdit.user && (
                              <div className={classes.small}>
                                <strong>{p.lastEdit.user.email}</strong>
                                {' '}
                                at
                                {' '}
                                <strong>{p.lastEdit.timestamp}</strong>
                              </div>
                              )
                            }
                            <div>
                              {
                                editablePolygons && (
                                  <FormControlLabel
                                    control={(
                                      <Switch
                                        disabled={!this.canEdit()}
                                        checked={p.properties.parkingLot || false}
                                        name="parkingLot"
                                        onChange={(event) => this.handlePolygonPropertyChange(event, p.index, true)}
                                        color="primary"
                                      />
                                          )}
                                    label="Parking Lot"
                                  />
                                )
                              }
                              {
                                editablePolygons && (
                                  <FormControlLabel
                                    control={(
                                      <Switch
                                        disabled={!this.canEdit()}
                                        checked={p.properties.nap || false}
                                        name="nap"
                                        onChange={(event) => this.handlePolygonPropertyChange(event, p.index, true)}
                                        color="primary"
                                      />
                                          )}
                                    label="NAP"
                                  />
                                )
                              }
                              {
                                editablePolygons && !p.properties.parkingLot && ali.category !== C.CATEGORY_REAL_ESTATE && (
                                  <TextField
                                    disabled={!this.canEdit()}
                                    variant="outlined"
                                    fullWidth
                                    name="suiteNumber"
                                    label="Suite Number"
                                    type="text"
                                    size="small"
                                    margin="dense"
                                    value={p.properties.suiteNumber || ''}
                                    onChange={(event) => this.handlePolygonPropertyChange(event, p.index)}
                                  />
                                )
                              }
                            </div>
                            {
                              User.canEditCoordinates() && p.coordinates && (
                                <div className={classes.small}>
                                  { expandedCoordinates[p.index] ? (
                                    <>
                                      <Button size="small" variant="text" onClick={() => this.handleShowCoordinated(p.index, false)} endIcon={<ExpandLessIcon />}>Hide Coordinates</Button>
                                      { p.coordinates.filter((c, cidx) => cidx < p.coordinates.length - 1).map((c, cidx) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={cidx} className={classes.vertexLine}>
                                          <Button
                                            variant="text"
                                            size="small"
                                            className={clsx(classes.vertex, (duplicatedVertex[p.index] || {})[cidx] && classes.duplicateVertex, p.index === currentPolygonIndex && cidx === currentVertexIndex && classes.selectedVertex)}
                                            onClick={() => this.handleVertexSelect(p.index, cidx)}
                                          >
                                            {`${c[1]}, ${c[0]}`}
                                          </Button>
                                          { p.coordinates.length > 4 && (
                                            <IconButton
                                              color="secondary"
                                              size="small"
                                              onClick={() => this.handleVertexDelete(p.index, cidx)}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          )}
                                        </div>
                                      )) }
                                    </>
                                  ) : (
                                    <Button size="small" variant="text" onClick={() => this.handleShowCoordinated(p.index, true)} endIcon={<ExpandMoreIcon />}>Show Coordinates</Button>
                                  )}
                                </div>
                              )
                            }
                          </ListItemText>
                        </ListItem>
                      ))
                    }
                  </List>
                </div>
              )
              : (
                <div />
              )
            )
          }
      </div>
    );
  }
}

LocationProperties.defaultProps = {
  ali: null,
  nearByAlis: [],
  createALILists: [],
  editablePolygons: true,
  onAlert: () => { },
  onLinkALI: () => { },
  onSwitchToALI: () => { },
  onALIAreaSearch: () => { },
  selectedPolygon: undefined,
  onPolygonSelected: () => {},
  onShowOverlapRisks: () => {},
};

LocationProperties.propTypes = {
  ali: PropTypes.shape(),
  nearByAlis: PropTypes.arrayOf(PropTypes.shape()),
  createALILists: PropTypes.arrayOf(PropTypes.shape()),
  editablePolygons: PropTypes.bool,
  onAlert: PropTypes.func,
  onLinkALI: PropTypes.func,
  onSwitchToALI: PropTypes.func,
  onALIAreaSearch: PropTypes.func,
  selectedPolygon: PropTypes.shape(),
  onPolygonSelected: PropTypes.func,
  onShowOverlapRisks: PropTypes.func,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(LocationProperties);
