import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ISSUES_LEVEL } from '../constants';
import * as C from '../constants';
import { withStylesPropTypes } from '../helper/misc';

const useStyles = ((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    '&>.MuiDialogTitle-root': {
      flexGrow: 1,
    },
  },
  navigation: {
    alignSelf: 'center',
    flexGrow: 0,
    display: 'flex',
    '&>div': {
      alignSelf: 'center',
    },
  },
  locateButton: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  overlapFlag: {
    paddingTop: theme.spacing(2),
    fontSize: '0.9rem',
  },
}));

class ValidationIssues extends React.Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }

  closeDialog() {
    const { onClose } = this.props;
    onClose();
  }

  handleOK() {
    const { onOK } = this.props;
    onOK();
  }

  handleCancel() {
    this.closeDialog();
  }

  handleNavigateIssues(step) {
    const { currentIndex } = this.state;
    this.setState({
      currentIndex: currentIndex + step,
    });
  }

  handleVertexSelect(polygonIndex, vertexIndex) {
    const { onVertexSelect } = this.props;
    onVertexSelect(polygonIndex, vertexIndex);
  }

  handlePolygonSelect(polygonIndex) {
    const { onPolygonSelect } = this.props;
    onPolygonSelect(polygonIndex);
  }

  handleOverlapFlagSelect(event, aliId) {
    const flag = event.target.value;
    const { onOverlapPolygonFlagSelect, issues } = this.props;
    // set the overlapType in the issue so that we can disable the save anyway button
    issues.forEach((i) => {
      if (i.nearByALIId === aliId && flag) {
        // eslint-disable-next-line no-param-reassign
        i.overlapType = flag;
      }
    });
    onOverlapPolygonFlagSelect(aliId, flag);
  }

  render() {
    const { issues, classes } = this.props;
    const { currentIndex } = this.state;

    const issueLevelToIndex = (level) => {
      switch (level) {
        case ISSUES_LEVEL.ERROR: return 0;
        case ISSUES_LEVEL.WARNING: return 1;
        case ISSUES_LEVEL.INFO: return 2;
        default: return 0;
      }
    };

    const issuesToDisplay = issues.slice();
    issuesToDisplay.sort((i1, i2) => {
      const l1 = issueLevelToIndex(i1.level);
      const l2 = issueLevelToIndex(i2.level);
      return l1 - l2;
    });

    const currentIssue = issuesToDisplay[currentIndex];

    const stats = issuesToDisplay.reduce((s, i) => ({
      ...s,
      [i.level]: (s[i.level] || 0) + 1,
    }), {});

    const errorsNum = stats[ISSUES_LEVEL.ERROR] || 0;
    const warningsNum = stats[ISSUES_LEVEL.WARNING] || 0;
    const infosNum = stats[ISSUES_LEVEL.INFO] || 0;

    let title;
    if (errorsNum === 1 && warningsNum === 0 && infosNum === 0) {
      title = 'Error';
    } else if (errorsNum === 0 && warningsNum === 1 && infosNum === 0) {
      title = 'Warning';
    } else if (errorsNum === 0 && warningsNum === 0 && infosNum === 1) {
      title = 'Info';
    } else {
      title = 'Issues';
    }

    // Can not use save Anyways button if issuesToDisplay has a coBranded or/and withInStore overlap issue
    const overlappingIssues = issuesToDisplay.filter((i) => i.type && i.type === C.ISSUES_TYPE_OVERLAPPED_POLYGONS);
    const hasPolygonOverlapIssues = (overlappingIssues || []).every((i) => i.overlapType && [C.ALI_FLAG_COBRANDED, C.ALI_FLAG_WITHINSTORE, C.ALI_FLAG_TOUCHING_BORDERS].includes(i.overlapType));
    const canSave = _.isEmpty(overlappingIssues) || hasPolygonOverlapIssues;

    return (
      <Dialog
        open
        onClose={() => this.handleCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.title}>
          <DialogTitle id="alert-dialog-title">
            { title }
          </DialogTitle>
          {
            issuesToDisplay.length > 1 && (
              <div className={classes.navigation}>
                <IconButton color="primary" onClick={() => this.handleNavigateIssues(-1)} disabled={currentIndex === 0}>
                  <ArrowBackIosIcon />
                </IconButton>
                <div>
                  {currentIndex + 1}
                  {' '}
                  of
                  {' '}
                  {issuesToDisplay.length}
                </div>
                <IconButton color="primary" onClick={() => this.handleNavigateIssues(1)} disabled={currentIndex === issuesToDisplay.length - 1}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            )
          }
        </div>
        <DialogContent>
          <Alert severity={currentIssue.level}>{currentIssue.message}</Alert>
          { currentIssue.polygonIndex >= 0 && currentIssue.pointIndex >= 0 && (
            <div className={classes.locateButton}>
              <Button
                onClick={() => this.handleVertexSelect(currentIssue.polygonIndex, currentIssue.pointIndex)}
                variant="outlined"
                startIcon={<MyLocationIcon />}
              >
                Select Vertex
              </Button>
            </div>
          )}
          {
            (currentIssue.type === C.ISSUES_TYPE_OVERLAPPED_POLYGONS && currentIssue.polygonType === C.POLYGON_TYPE_RETAILER) && (
              <div className={classes.overlapBlock}>
                <div>
                  Please select Co-Branded if
                  {' '}
                  {currentIssue.aliName}
                  {' '}
                  is a Co-Brand of
                  {' '}
                  { currentIssue.nearByAliName}
                  {' '}
                  OR select WithIn Store to mark
                  {' '}
                  { currentIssue.aliName}
                  {' '}
                  is within the
                  {' '}
                  { currentIssue.nearByAliName}
                  .
                </div>
                <div m={2}>

                  <RadioGroup name="overlappedPolygonFlag" className={classes.overlapFlag} value={currentIssue.overlapType} onChange={(e) => this.handleOverlapFlagSelect(e, currentIssue.nearByALIId)}>
                    <FormControlLabel value="coBranded" control={<Radio color="primary" />} label="Co-Branded" />
                    <FormControlLabel value="withInStore" control={<Radio color="primary" />} label="WithIn Store" />
                    <FormControlLabel value="touchingBorders" control={<Radio color="primary" />} label="Touching Borders" />
                  </RadioGroup>
                </div>
              </div>
            )
          }
          { currentIssue.polygonIndex >= 0 && currentIssue.pointIndex === undefined && (
          <div className={classes.locateButton}>
            <Button
              onClick={() => this.handlePolygonSelect(currentIssue.polygonIndex)}
              startIcon={<MyLocationIcon />}
              variant="outlined"
            >
              Select Polygon
            </Button>
          </div>
          )}
        </DialogContent>
        <DialogActions>
          { errorsNum > 0 && (
            <Button onClick={() => this.handleCancel()} color="primary">Close</Button>
          )}
          { errorsNum === 0 && warningsNum > 0 && (
            <>
              <Button onClick={() => this.handleOK()} color="primary" disabled={!canSave}>Save Anyway</Button>
              <Button onClick={() => this.handleCancel()} color="primary">Cancel</Button>
            </>
          )}
          { errorsNum === 0 && warningsNum === 0 && (
            <Button onClick={() => this.handleOK()} color="primary">OK</Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ValidationIssues.defaultProps = {
  onOK: () => {},
  onClose: () => {},
  onPolygonSelect: () => {},
  onVertexSelect: () => {},
  onOverlapPolygonFlagSelect: () => {},
  issues: {},
};

ValidationIssues.propTypes = {
  ...withStylesPropTypes,
  onOK: PropTypes.func,
  onClose: PropTypes.func,
  onPolygonSelect: PropTypes.func,
  onVertexSelect: PropTypes.func,
  onOverlapPolygonFlagSelect: PropTypes.func,
  issues: PropTypes.arrayOf(PropTypes.shape()),
};

export default withStyles(useStyles)(ValidationIssues);
