import axios from 'axios';
import Config from '../config';
import { error } from '../helper/misc';
import * as C from '../constants';
import User from './user';

class ALIAPI {
  constructor() {
    this.axios = axios;
  }

  get(ali) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}alis/${ali}`)
        .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  updateALI(aliData, options) {
    const me = this;
    const updateOptions = {
      coordinates: false,
      polygonStatus: false,
      locked: false,
      property: false,
      ...options,
    };

    const data = {
      changes: {
        "hideTraffic":aliData.hideTraffic,
        "hideFromApp":aliData.hideFromApp
      },
      ali: aliData.ali,
    };

    if (updateOptions.polygonStatus) {
      data.changes.polygonStatus = aliData.polygonStatus;
    }
    if (updateOptions.locked) {
      data.changes.locked = !!aliData.locked;
    }
    if (updateOptions.property) {
      data.changes.propertyId = aliData.propertyId;
    }
    if (updateOptions.coordinates) {
      data.changes.geoAccuracy = aliData.geoAccuracy;
      data.coordinates = [aliData.longitude, aliData.latitude];
    }

    if (updateOptions.coBrandedAliModified) {
      data.changes.coBranded = aliData.coBranded || false;
      data.changes.coBrandedAli = aliData.coBrandedAli || [];
    }

    if (updateOptions.withInStoreAliModified) {
      data.changes.withInStore = aliData.withInStore || false;
      data.changes.withInStoreAli = aliData.withInStoreAli || [];
    }

    if (updateOptions.touchingBordersModified) {
      data.changes.touchingBordersWithAli = aliData.touchingBordersWithAli || [];
    }

    if (updateOptions.manualSqftModified) {
      data.changes.manualSqft = aliData.manualSqft;
    }

    return new Promise((resolve, reject) => {
      if (!Object.keys(data.changes).length) {
        reject(new Error('Nothing to update'));
      } else {
        me.axios.post(`${Config.api_endpoint}alis/update`, data)
          .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
          .catch((err) => reject(error(err)));
      }
    });
  }

  createALI(params) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.post(`${Config.api_endpoint}alis/create`, params)
        .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  lockALI(ali, locked = true) {
    return this.updateALI({ ali, locked }, { locked: true });
  }

  getALILocks() {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}alis/locked`)
        .then((result) => resolve(result.data))
        .catch((err) => reject(error(err)));
    });
  }

  updateGeofence(aliData) {
    const me = this;

    const createData = { ali: aliData.ali, propertyId: aliData.property._id, geofences: [] };
    const updateData = { ali: aliData.ali, propertyId: aliData.property._id, geofences: [] };
    const deleteData = { ali: aliData.ali, propertyId: aliData.property._id, geofences: [] };

    if (aliData.polygons) {
      aliData.polygons.forEach((p) => {
        const geofence = (!p.deleted ? {
          geometry: {
            coordinates: [p.coordinates],
            type: 'Polygon',
          },
          properties: { ...p.properties, name: p.name },
          type: 'Feature',
        } : {});
        if (p.id) geofence._id = p.id;

        if (p.deleted) {
          deleteData.geofences.push(geofence);
        } else if (p.id) {
          updateData.geofences.push(geofence);
        } else {
          createData.geofences.push(geofence);
        }
      });
    }

    if (!createData.geofences.length && !updateData.geofences.length && !deleteData.geofences.length) {
      return new Promise((resolve, reject) => {
        me.axios.post(`${Config.api_endpoint}geofences`, createData)
          .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
          .catch((err) => reject(error(err)));
      });
    }
    const promises = [];
    if (createData.geofences.length) {
      promises.push(new Promise((resolve, reject) => {
        me.axios.post(`${Config.api_endpoint}geofences`, createData)
          .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
          .catch((err) => reject(error(err)));
      }));
    }

    if (updateData.geofences.length) {
      promises.push(new Promise((resolve, reject) => {
        me.axios.post(`${Config.api_endpoint}geofences/update`, updateData)
          .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
          .catch((err) => reject(error(err)));
      }));
    }

    if (deleteData.geofences.length) {
      promises.push(new Promise((resolve, reject) => {
        me.axios.post(`${Config.api_endpoint}geofences/delete`, deleteData)
          .then((result) => resolve(ALIAPI.convertALIFromAPI(result.data)))
          .catch((err) => reject(error(err)));
      }));
    }

    return Promise.all(promises);
  }

  updateProperty(aliData) {
    const me = this;
    return new Promise((resolve, reject) => {
      if (aliData.property) {
        me.axios.post(`${Config.api_endpoint}properties/${aliData.property._id ? 'update' : ''}`, { property: aliData.property })
          .then((result) => resolve(result.data))
          .catch((err) => reject(error(err)));
      }
    });
  }


  getLists(page) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}lists${ALIAPI.getAliQueryString({ page: page || 0 })}`)
        .then((result) => resolve(ALIAPI.convertListsFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getList(listId, page) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}lists/${listId}/alis${ALIAPI.getAliQueryString({ page: page || 0 })}`)
        .then((result) => resolve(ALIAPI.convertListFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getAlisInBounds(bounds) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}alis/searchBox${ALIAPI.getAliQueryString({ leftBottom: bounds.sw.join(','), rightUpper: bounds.ne.join(',') })}`)
        .then((result) => resolve(ALIAPI.filterOutALIs(result.data).map((v) => ALIAPI.convertALIFromAPI(v))))
        .catch((err) => reject(error(err)));
    });
  }

  getAlisInRing(lat, lng, radius, includeGeofences = false) {
    const me = this;
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line object-curly-newline
      me.axios.get(`${Config.api_endpoint}alis/radius${ALIAPI.getAliQueryString({ lat, lng, radius, includeGeofences })}`)
        .then((result) => resolve(ALIAPI.filterOutALIs(result.data).map((v) => ALIAPI.convertALIFromAPI(v))))
        .catch((err) => reject(error(err)));
    });
  }

  getAlisByParent(ali) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}alis/parent/${ali}`)
        .then((result) => resolve(ALIAPI.filterOutALIs(result.data).map((v) => ALIAPI.convertALIFromAPI(v))))
        .catch((err) => reject(error(err)));
    });
  }

  searchByAli(ali) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}alis/search/${ali}`)
        .then((result) => resolve(ALIAPI.filterOutALIs(result.data).map((v) => ALIAPI.convertALIFromAPI(v))))
        .catch((err) => reject(error(err)));
    });
  }

  getWithParentList(page) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}alis/withParent${ALIAPI.getAliQueryString({ page: page || 0 })}`)
        .then((result) => resolve(ALIAPI.convertListFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getGeographies(page) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}geographies${ALIAPI.getQueryString({ page: page || 0 })}`)
        .then((result) => resolve(ALIAPI.convertGeographiesFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getGeography(id) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}geographies/${id}`)
        .then((result) => resolve(ALIAPI.convertGeographyFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getOrphanProperties(page) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}properties${ALIAPI.getQueryString({ page: page || 0, withAli: false })}`)
        .then((result) => resolve(ALIAPI.convertPropertiesFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  getOrphanProperty(id) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.get(`${Config.api_endpoint}properties/${id}`)
        .then((result) => resolve(ALIAPI.convertPropertyFromAPI(result.data)))
        .catch((err) => reject(error(err)));
    });
  }

  searchAlisCsv(data) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.axios.post(`${Config.api_endpoint}alis/findAlis`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((result) => {
          const { alisFound } = result.data;
          const mappedALIs = ALIAPI.filterOutALIs(alisFound).map((v) => ALIAPI.convertALIFromAPI(v));
          // eslint-disable-next-line no-param-reassign
          result.data.alisFound = mappedALIs;
          resolve(result.data);
        })
        .catch((err) => reject(error(err)));
    });
  }

  static getQueryString(params) {
    const ret = [];

    if (params) {
      Object.keys(params).forEach((p) => {
        ret.push(`${p}=${params[p]}`);
      });
    }

    return (ret.length ? `?${ret.join('&')}` : '');
  }

  static getAliQueryString(params) {
    const queryParams = params;

    if (Config.distributors) {
      switch (Config.distributors) {
        case 'ON':
          queryParams.distributor = 'true';
          break;
        case 'OFF':
          queryParams.distributor = 'false';
          break;
        default:
          break;
      }
    }

    const statuses = User.getAllowedALIStatuses();
    if (statuses) {
      queryParams.polygonStatus = statuses.join(',');
    }

    return ALIAPI.getQueryString(queryParams);
  }

  static geofencesToPolygons(geofences, pidxStart) {
    const ret = [];
    const fnAddPolygon = (coordinates, properties, createdAt, updatedAt, id, idx) => {
      const coords = coordinates[0];
      const last = coords[coords.length - 1];
      const first = coords[0];
      if (Math.abs(last[0] - first[0]) > Number.EPSILON
        || Math.abs(last[1] - first[1]) > Number.EPSILON) {
        coords.push(first);
      }
      let name;
      if (properties) {
        if (properties.name) name = properties.name;
        else if (properties.thasosPolygonName) name = properties.thasosPolygonName;
        else name = `Polygon ${idx}`;
      }
      ret.push({
        id,
        name,
        properties: properties || {},
        coordinates: coords,
        createdAt,
        updatedAt,
      });
    };
    let pidx = pidxStart;
    geofences.forEach((g) => {
      if (g.geometry && g.geometry.coordinates && g.geometry.coordinates.length && User.canSeeGeofence(g)) {
        if (g.geometry.type && g.geometry.type.toLowerCase() === 'multipolygon') {
          for (let i = 0; i < g.geometry.coordinates.length; i += 1) {
            pidx += 1;
            fnAddPolygon(
              g.geometry.coordinates[i],
              g.properties,
              g.createdAt,
              g.updatedAt,
              i === 0 ? g._id : undefined,
              pidx,
            );
          }
        } else {
          pidx += 1;
          fnAddPolygon(
            g.geometry.coordinates,
            g.properties,
            g.createdAt,
            g.updatedAt,
            g._id,
            pidx,
          );
        }
      }
    });
    return ret;
  }

  static convertALIFromAPI(data) {
    let ret = null;
    if (typeof (data) === 'object') {
      let pidx = 0;
      ret = { polygons: [] };
      Object.keys(data).forEach((key) => {
        if (key === 'geofences') {
          const polygons = ALIAPI.geofencesToPolygons(data[key], pidx);
          pidx += polygons.length;
          ret.polygons.push(...polygons);
        } else if (key === 'property') {
          const propertyList = data[key];
          ret.property = propertyList.length ? propertyList[0] : {};
        } else if (key === 'location') {
          if (data[key]) [ret.longitude, ret.latitude] = data[key].coordinates;
        } else if (key === 'originalLocation') {
          [ret.original_longitude, ret.original_latitude] = data[key].coordinates;
        } else {
          const value = data[key];
          switch (key) {
            case 'listId': ret.listID = value; break;
            case 'zipCode': ret.zip = value; break;
            case 'phoneNumber': ret.phone = value; break;
            case 'squareFootage': ret.squareFootage = Math.round(value); break;
            case 'latitude': break;
            case 'longitude': break;
            default: ret[key] = value; break;
          }
        }
      });
      ret.sc = ret.parent ? 'Y' : 'N';
    }
    return ret;
  }

  static convertGeographyFromAPI(data) {
    let ret = null;
    if (typeof (data) === 'object') {
      let pidx = 0;
      ret = { polygons: [] };
      Object.keys(data).forEach((key) => {
        if (key === 'geofences') {
          const polygons = ALIAPI.geofencesToPolygons(data[key], pidx);
          pidx += polygons.length;
          ret.polygons.push(...polygons);
        } else if (key === 'property') {
          const propertyList = data[key];
          ret.property = propertyList.length ? propertyList[0] : {};
        } else if (key === 'location') {
          [ret.longitude, ret.latitude] = data[key].coordinates;
        } else if (key === 'originalLocation') {
          [ret.original_longitude, ret.original_latitude] = data[key].coordinates;
        } else {
          const value = data[key];
          switch (key) {
            case 'name': ret.storeName = value; break;
            case '_id': ret.ali = value; break;
            default: ret[key] = value; break;
          }
        }
      });
      ret.listID = C.LIST_ID.GEOGRAPHIES;
      ret.listName = C.LIST_NAME[ret.listID];
      ret.polygonStatus = C.STATUS_VERIFIED_ADMIN;
    }
    return ret;
  }

  static convertPropertyFromAPI(data) {
    let ret = null;
    if (typeof (data) === 'object') {
      let pidx = 0;
      ret = { polygons: [], property: {} };
      Object.keys(data).forEach((key) => {
        if (key === 'geofences') {
          const polygons = ALIAPI.geofencesToPolygons(data[key], pidx);
          pidx += polygons.length;
          ret.polygons.push(...polygons);
        } else {
          ret.property[key] = data[key];
        }
      });
      ret.storeName = ret.property.requestFlag && ret.property.requestNames && ret.property.requestNames.length ? ret.property.requestNames[0] : null;
      ret.address = ret.property.name;
      ret.listID = C.LIST_ID.ORPHAN;
      ret.listName = C.LIST_NAME[ret.listID];
      ret.ali = ret.property._id;
      ret.polygonStatus = C.STATUS_VERIFIED_ADMIN;

      if (ret.polygons && ret.polygons.length) {
        const poly = ret.polygons.slice().sort((a, b) => {
          const n1 = a.coordinates ? a.coordinates.length : 0;
          const n2 = b.coordinates ? b.coordinates.length : 0;
          return n2 - n1;
        })[0];
        if (poly.coordinates && poly.coordinates.length) {
          const istep = Math.floor(poly.coordinates.length / 3);
          const indexes = [0];
          if (istep > 0) indexes.push(istep);
          if (istep * 2 < poly.coordinates.length) indexes.push(istep * 2);

          let plat = 0;
          let plng = 0;
          indexes.forEach((i) => {
            plat += poly.coordinates[i][1];
            plng += poly.coordinates[i][0];
          });

          ret.latitude = (plat / indexes.length);
          ret.longitude = (plng / indexes.length);
        }
      }
    }
    return ret;
  }

  static convertListsFromAPI(data) {
    return {
      total: data.total,
      offset: data.offset,
      limit: data.limit,
      docs: data.docs.map((d) => {
        const goldenSet = d.goldenSetStats || {};
        const goldenSetTotal = goldenSet.usNonDistributorNumber || 0;
        return {
          listID: d.listId,
          listName: d.shortName,
          category: d.category,
          usLocations: d.usNonDistributorNumber,
          usLocationsGoldenSet: goldenSetTotal,
          aggDataVerifiedLocations: d.aggdataVerifiedNumber,
          aggDataVerifiedLocationsGoldenSet: goldenSet.aggdataVerifiedNumber || 0,
          aggDataVerifiedPercent: d.usNonDistributorNumber > 0 ? Math.round((d.aggdataVerifiedNumber * 100) / d.usNonDistributorNumber) : undefined,
          aggDataVerifiedPercentGoldenSet: goldenSetTotal > 0 ? Math.round((goldenSet.aggdataVerifiedNumber * 100) / goldenSetTotal) : undefined,
          polygonMatches: d.polygonMatchesNumer,
          polygonMatchesGoldenSet: goldenSet.polygonMatchesNumer || 0,
          polygonMatchPercent: d.usNonDistributorNumber > 0 ? Math.round((d.polygonMatchesNumer * 100) / d.usNonDistributorNumber) : undefined,
          polygonMatchPercentGoldenSet: goldenSetTotal > 0 ? Math.round((goldenSet.polygonMatchesNumer * 100) / goldenSetTotal) : undefined,
          verifiedPolygonMatches: d.verifiedPolygonMatchesNumer,
          verifiedPolygonMatchesGoldenSet: goldenSet.verifiedPolygonMatchesNumer || 0,
          users: d.users,
        };
      }),
    };
  }

  static convertListFromAPI(data) {
    return {
      total: data.total,
      offset: data.offset,
      limit: data.limit,
      docs: ALIAPI.filterOutALIs(data.docs).map((v) => ALIAPI.convertALIFromAPI(v)),
    };
  }

  static filterOutALIs(list) {
    return (list || []).filter((v) => User.canAccessALI(v));
  }

  static convertGeographiesFromAPI(data) {
    return {
      total: data.total,
      offset: data.offset,
      limit: data.limit,
      docs: ALIAPI.filterOutALIs(data.docs.map((v) => ALIAPI.convertGeographyFromAPI(v))),
    };
  }

  static convertPropertiesFromAPI(data) {
    return {
      total: data.total,
      offset: data.offset,
      limit: data.limit,
      docs: ALIAPI.filterOutALIs(data.docs.map((v) => ALIAPI.convertPropertyFromAPI(v))),
    };
  }
}

export default new ALIAPI();
