export const COLOR_SPECIAL_1 = 1000;

class ColorProvider {
  constructor() {
    this.COLORS = ['#ff0000', '#ff8800', '#008000', '#000080', '#800080'];
    this.colorIndex = 0;
    this.COLOR_SPECIAL_1_RGB = '#ffff00';
  }

  reset(index = 0) {
    this.colorIndex = index;
  }

  next() {
    const color = this.get(this.colorIndex);
    this.colorIndex += 1;
    return color;
  }

  get(index, opacity = 1) {
    let color;
    if (index === COLOR_SPECIAL_1) color = this.COLOR_SPECIAL_1_RGB;
    else color = this.COLORS[index % this.COLORS.length];

    if (opacity < 1) {
      const r = parseInt(color.substr(1, 2), 16);
      const g = parseInt(color.substr(3, 2), 16);
      const b = parseInt(color.substr(5, 2), 16);
      color = `rgba(${r},${g},${b},${opacity})`;
    }

    return color;
  }
}

export default ColorProvider;
