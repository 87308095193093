import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AutoSizer, Column, Table } from 'react-virtualized';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import { withStylesPropTypes, sortArray, filterArray } from '../helper/misc';

const useStyles = ((theme) => ({
  root: {
    width: '100%',
  },
  flexContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  multLineHeader: {
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stickyRow: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: '#f3f399',
    width: '100%',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
  },
  tableHeaderCell: {
    width: '100%',
    flexGrow: 1,
    flexBasis: 0,
    cursor: 'pointer',
    backgroundColor: theme.palette.info.light,
    color: theme.palette.text.primary,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    borderRight: `solid 1px ${theme.palette.action.hover}`,
    '&:focus': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      color: theme.palette.text.secondary,
      '& $sortIcon': {
        opacity: 0.5,
      },
    },
    '&$active': {
      color: theme.palette.text.primary,
      '&& $sortIcon': {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
    '&$desc': {
      '&& $sortIcon': {
        transform: 'rotate(180deg)',
      },
    },
  },
  active: { },
  desc: { },
  sortIcon: {
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
    opacity: 0,
  },
  filterIcon: {
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
    color: theme.palette.text.secondary,
  },
  filterActive: {
    color: theme.palette.warning.main,
  },
}));

class TableView extends React.Component {
  constructor(props) {
    super(props);

    const columnFilterState = [];
    if (props.filters) {
      Object.keys(props.filters).forEach((f) => {
        const index = props.columns.findIndex((v) => v.dataKey === f);
        if (index >= 0) {
          columnFilterState[index] = { value: props.filters[f] };
        }
      });
    }

    this.state = {
      activeColumnIndex: -1,
      columnSortState: [],
      columnFilterState,
      originalData: (props.data ? props.data.slice() : []),
      data: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    const { data, getTableDataCallback } = this.props;
    if (data !== prevProps.data) {
      this.onDataChanged(data);
    }
    if (prevProps.getTableDataCallback !== getTableDataCallback && getTableDataCallback) {
      this.onGetTableData(getTableDataCallback);
    }
  }

  onGetTableData(callback) {
    const { data } = this.state;
    const { columns } = this.props;
    if (callback) callback({ columns, data });
  }

  onDataChanged(newData) {
    this.setState({
      data: this.filterData(newData),
      originalData: newData,
    });
  }

  onColumnFilter(columnFilter) {
    const { onColumnFilter = (() => {}) } = this.props;
    const { originalData } = this.state;

    this.setState({
      data: this.filterData(originalData),
    });

    onColumnFilter(columnFilter);
  }

  onColumnSort(columnSort) {
    const { onColumnSort = (() => {}) } = this.props;
    const { data } = this.state;

    this.sortData(data);
    this.setState({
      data,
    });

    onColumnSort(columnSort);
  }

  getRowClassName({ index }) {
    const { classes, getRowClassName } = this.props;
    const { data } = this.state;
    return clsx(classes.tableRow, classes.flexContainer, {
      ...getRowClassName(index >= 0 ? data[index] : null),
      [classes.tableRowHover]: index !== -1,
    });
  }

  filterData(data) {
    const { columnFilterState } = this.state;
    const { columns } = this.props;

    const columnsFilter = [];
    columnFilterState.forEach((f, i) => {
      if (f.value) {
        columnsFilter.push({
          columnDef: columns[i],
          value: f.value,
        });
      }
    });

    const filteredData = filterArray(data, columnsFilter);
    this.sortData(filteredData);
    return filteredData;
  }

  sortData(data) {
    const { activeColumnIndex, columnSortState } = this.state;
    const { columns } = this.props;
    const sortState = columnSortState[activeColumnIndex];
    if (sortState !== undefined) {
      sortArray(data, columns[activeColumnIndex], sortState);
    }
  }

  handleSort(index) {
    const { activeColumnIndex, columnSortState, columnFilterState } = this.state;
    if (columnFilterState[index] && columnFilterState[index].editing) return;

    columnSortState[index] = columnSortState[index] ? activeColumnIndex !== index : activeColumnIndex === index;
    this.setState({
      activeColumnIndex: index,
      columnSortState,
    });
    this.onColumnSort({ index, desc: columnSortState[index] });
  }

  handleColumnKeyDown(e, index) {
    if (e.key === ' ') this.handleSort(index);
  }

  showColumnFilter(e, index) {
    e.preventDefault();
    e.stopPropagation();

    const state = { ...this.state };
    if (!state.columnFilterState[index]) state.columnFilterState[index] = {};
    state.columnFilterState[index].editing = true;
    this.setState(state);
  }

  handleColumnFilter(event, index) {
    const { value } = event.target;
    const { columnFilterState } = this.state;
    columnFilterState[index].value = value;
    this.setState({
      columnFilterState,
    });
  }

  handleColumnFilterEnd(event, index) {
    const { type, which, target } = event;

    if (type === 'keydown') {
      if (which === 13) {
        target.blur();
      }
    } else {
      const { columnFilterState } = this.state;
      columnFilterState[index].editing = false;
      this.setState({
        columnFilterState,
      });
      this.onColumnFilter({ index, value: columnFilterState[index].value });
    }
  }

  headerRenderer({ label, dataKey, columnIndex }) {
    const {
      classes, headerHeight, rowHeight, stickyRow, columns, onStickyRowClick,
    } = this.props;
    const { activeColumnIndex, columnSortState, columnFilterState } = this.state;
    return (
      <div
        className={clsx(classes.header, classes.flexContainer, stickyRow && classes.multLineHeader)}
        style={{ height: headerHeight + (stickyRow ? rowHeight : 0) }}
      >
        <div
          className={clsx(classes.tableHeaderCell, {
            [classes.active]: columnIndex === activeColumnIndex,
            [classes.desc]: columnSortState[columnIndex],
          })}
          tabIndex={columnIndex}
          role="button"
          onClick={() => this.handleSort(columnIndex)}
          onKeyDown={(e) => this.handleColumnKeyDown(e, columnIndex)}
        >
          {
                    (columnFilterState[columnIndex] && columnFilterState[columnIndex].editing
                      ? (
                        <div className={classes.flexContainer}>
                          <TextField
                            name="filter"
                            label={label}
                            type="text"
                            id={`filter_${dataKey}`}
                            value={columnFilterState[columnIndex].value || ''}
                            autoFocus
                            onChange={(event) => this.handleColumnFilter(event, columnIndex)}
                            onBlur={(event) => this.handleColumnFilterEnd(event, columnIndex)}
                            onKeyDown={(event) => this.handleColumnFilterEnd(event, columnIndex)}
                          />

                        </div>
                      )
                      : (
                        <div className={classes.flexContainer}>
                          <FilterListIcon className={clsx(classes.filterIcon, { [classes.filterActive]: columnFilterState[columnIndex] && columnFilterState[columnIndex].value })} onClick={(e) => this.showColumnFilter(e, columnIndex)} />
                          <span>{label}</span>
                          <ArrowDownwardIcon className={classes.sortIcon} />
                        </div>
                      )
                    )
                }
        </div>
        {
          stickyRow && (
            <div
              className={classes.stickyRow}
              style={{ height: rowHeight }}
              onClick={() => onStickyRowClick(stickyRow)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={columnIndex}
            >
                {
                  columns[columnIndex].format
                    ? columns[columnIndex].format(stickyRow[dataKey], columnFilterState[columnIndex])
                    : stickyRow[dataKey]
                }
            </div>
          )
        }
      </div>
    );
  }

  cellRenderer({ cellData, columnIndex }) {
    const { classes, rowHeight, columns } = this.props;
    const { columnFilterState } = this.state;
    return (
      <div
        className={clsx(classes.tableCell, classes.flexContainer)}
        style={{ height: rowHeight }}
      >
        {
          columns[columnIndex].format
            ? columns[columnIndex].format(cellData, columnFilterState[columnIndex])
            : cellData
        }
      </div>
    );
  }

  render() {
    const {
      classes, columns, rowHeight, headerHeight, stickyRow, onRowClick,
    } = this.props;
    const { data } = this.state;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            headerHeight={headerHeight + (stickyRow ? rowHeight : 0)}
            className={classes.table}
            rowClassName={(params) => this.getRowClassName(params)}
            onRowClick={onRowClick}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
          >
            {columns.map(({
              label, dataKey, width: columnWidth, flexGrow, headerClassName, className, getter,
            }, index) => (
             dataKey !== 'manualSqft' && <Column
                key={dataKey}
                headerRenderer={(headerProps) => this.headerRenderer({
                  ...headerProps,
                  columnIndex: index,
                })}
                cellRenderer={(cellProps) => this.cellRenderer({
                  ...cellProps,
                  columnIndex: index,
                })}
                label={label}
                cellDataGetter={getter}
                dataKey={dataKey}
                width={columnWidth}
                flexGrow={flexGrow}
                headerClassName={headerClassName}
                className={className}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

TableView.defaultProps = {
  headerHeight: 48,
  rowHeight: 48,
  stickyRow: null,
  filters: {},
  getRowClassName: () => {},
  onStickyRowClick: () => {},
};

TableView.propTypes = {
  stickyRow: PropTypes.shape(),
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  filters: PropTypes.shape(),
  getRowClassName: PropTypes.func,
  onStickyRowClick: PropTypes.func,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(TableView);
