import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withStylesPropTypes } from '../helper/misc';
import MapAddressBar from './mapaddressbar';
import ALISearchBar from './alisearchbar';

window.google = window.google ? window.google : {};
const { google } = window;

const useStyles = (() => ({
  root: {
    width: '100%',
  },
}));

class MapSearchBar extends React.Component {
  constructor() {
    super();

    this.searchBar = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { map } = this.props;

    if (prevProps.map !== map && map) {
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.searchBar.current);
    }
  }

  onSearchLatLng(latLng) {
    const { onSearchLatLng } = this.props;
    onSearchLatLng(latLng);
  }

  render() {
    const { classes, map, onALISearch } = this.props;

    return (
      <div ref={this.searchBar} className={classes.root}>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={5}><ALISearchBar onSearch={onALISearch} /></Grid>
          <Grid item xs={5}><MapAddressBar map={map} onSearchLatLng={(latLng) => this.onSearchLatLng(latLng)} /></Grid>
          <Grid item xs={1} />
        </Grid>
      </div>
    );
  }
}

MapSearchBar.defaultProps = {
  map: null,
  onALISearch: () => {},
  onSearchLatLng: () => {},
};

MapSearchBar.propTypes = {
  map: PropTypes.shape(),
  onALISearch: PropTypes.func,
  onSearchLatLng: PropTypes.func,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(MapSearchBar);
